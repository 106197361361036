import * as xlsx from "xlsx";

export function lerPlanilha<T>(arquivo: File) {
  const reader = new FileReader();

  return new Promise<T[]>((resolve, reject) => {
    reader.onload = (event) => {
      const arrayBuffer = event.target?.result as ArrayBuffer;
      const workbook = xlsx.read(new Uint8Array(arrayBuffer));
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      resolve(xlsx.utils.sheet_to_json(worksheet));
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(arquivo);
  });
}

export function validaPlanilha(
  arquivo: File,
  chavesPermitidas: string[],
  chavesObrigatorias: string[],
): Promise<{
  erro: boolean;
  mensagem?: string;
}> {
  const reader = new FileReader();

  return new Promise<{
    erro: boolean;
    mensagem?: string;
  }>((resolve, reject) => {
    reader.onload = (event) => {
      const arrayBuffer = event.target?.result as ArrayBuffer;
      const workbook = xlsx.read(new Uint8Array(arrayBuffer));
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      let cabecalhos: string[] = [];

      for (let key in worksheet) {
        let regEx = new RegExp("^(\\w)(1){1}$");
        if (regEx.test(key) == true) {
          cabecalhos.push(worksheet[key].v);
        }
      }

      for (const chaveObrigatoria of chavesObrigatorias) {
        if (!cabecalhos.includes(chaveObrigatoria)) {
          resolve({
            erro: true,
            mensagem: `Erro, a planilha selecionada não contém a coluna ${chaveObrigatoria} que é obrigatória`,
          });
        }
      }

      for (const celula of cabecalhos) {
        if (!chavesPermitidas.includes(celula)) {
          resolve({
            erro: true,
            mensagem: `Erro, a planilha selecionada contém a coluna ${celula} que não consta no modelo`,
          });
        }
      }

      resolve({
        erro: false,
      });
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(arquivo);
  });
}
