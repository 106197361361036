import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  padding: 10px;
  display: grid;
  gap: 0.5rem;
  box-shadow: 5px 3px 5px 2px rgba(0, 0, 0, 0.15);
  width: 500px;
  border-radius: 10px;
`;

export const ResumoContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.neutral05};
  padding: 2rem;
  display: grid;
  gap: 2rem;
  border-radius: 0.5rem;
`;

export const DadosContainer = styled.div`
  display: grid;
  font-size: 14px;
`;

export const TextoNegrito = styled.span`
  font-weight: 600;
`; 

export const TextoClaro = styled.span`
  color: ${({ theme }) => theme.colors.neutral.neutral40};
`;
