import styled from "styled-components";

export const ContainerForm = styled.div`
  padding: 20px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.neutral.neutral05};
`;

export const ContainerTexto = styled.div`
  margin: 24px 0;
`;
