import { Col, Row } from "react-bootstrap";
import {
  Container,
  DadosContainer,
  ResumoContainer,
  TextoClaro,
  TextoNegrito,
} from "./style";

interface IInfoEmplacamento {
  categoria: string;
  usuarioSolicitante: string;
}
export function ModalInfoEmplacamento({
  dados,
}: Readonly<{
  dados: IInfoEmplacamento;
}>) {
  return (
    <Container>
      <TextoClaro>Informações</TextoClaro>
      <ResumoContainer>
        <Row>
          <Col>
            <DadosContainer>
              <span>Categoria</span>
              <TextoNegrito>{dados.categoria ?? "-"}</TextoNegrito>
            </DadosContainer>
          </Col>
          <Col>
            <DadosContainer>
              <span>Usuário solicitante</span>
              <TextoNegrito>{dados.usuarioSolicitante ?? "-"}</TextoNegrito>
            </DadosContainer>
          </Col>
        </Row>
      </ResumoContainer>
    </Container>
  );
}
