import { ModalCustom } from "components/ModalCustom";
import { PrimaryButton } from "components/Button/style";
import { IconeContainer, InfoContainer, TextContainer } from "./style";
import { FaExclamation } from "react-icons/fa";

interface Props {
  show: boolean;
  handleClose: () => void;
  onSubmit: () => void;
}

export function ModalConfirmarAlterarDespachante({
  show,
  handleClose,
  onSubmit,
}: Props) {
  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      title="Alterar Despachante"
      centered
    >
      <InfoContainer>
        <IconeContainer>
          <FaExclamation />
        </IconeContainer>
        <TextContainer>
        Deseja alterar o despachante para os processos selecionados?
        </TextContainer>
      </InfoContainer>
      <div className="d-flex justify-content-between gap-4 mt-4">
        <PrimaryButton
          style={{ width: "14rem" }}
          outline
          variante="primary"
          onClick={handleClose}
        >
          Cancelar
        </PrimaryButton>
        <PrimaryButton style={{ width: "14rem" }} onClick={onSubmit}>
          Confirmar
        </PrimaryButton>
      </div>
    </ModalCustom>
  );
}
