import { AxiosRequest } from "_services/api";
import {
  IData,
  IHttpError,
  IHttpResponse,
} from "_services/api/interface/HttpResponse";
import { usePaginationSortSearch } from "containers/Table/hooks/usePaginationSortSearch";
import { useCallback, useState } from "react";
import { HttpResponse } from "_services/api/HttpResponse";
import { HttpError } from "_services/api/HttpError";
import { IFiltro } from "../Interfaces/IFiltro";
import { IListarEmplacamentosPR } from "../Interfaces/IListarEmplacamentosPR";
import { EtapaGerenciarEmplacamentosEnum } from "../enums/EtapaGerenciarEmplacamentos.enum";
import { IGerenciarEmplacamentos } from "../Interfaces/IGerenciarEmplacamentos";
import { IOptions } from "components/DropdownSelect";
import { IAtualizarProcesso } from "../Interfaces/IAtualizarProcesso";
import { IAgenteFinanceiro } from "../Interfaces/IAgenteFinanceiro";
import { IDespachante } from "../Interfaces/IDespachante";
import { IAtualizarDadosVeiculo } from "../Interfaces/IAtualizarDadosVeiculo";
import { toastAtencao, toastErro, toastSucesso } from "components/Toast";
import { IAtualizarProcessoLote } from "../Interfaces/IAtualizarProcessoLote";
import { getToken } from "_services/auth";
import { IGerarProcessoManualmente } from "../Interfaces/IGerarProcessoManualmente";
import { IEscolherPlacaManualmente } from "../Interfaces/IEscolherPlacaManualmente";
import { IEmitirLicenciamentoManualmente } from "../Interfaces/IEmitirLicenciamentoManualmente";
import { StatusEtapaGerenciarEmplacamentosEnum } from "../enums/StatusEtapaGerenciarEmplacamentos.enum";

export function useGerenciarEmplacamentos() {
  const msAw = AxiosRequest.makeApi(process.env.REACT_APP_MS_AW);
  const api = AxiosRequest.makeApi();
  const token = getToken();
  const { totalRows, setTotalRows } = usePaginationSortSearch();
  const [loading, setLoading] = useState(false);
  const [loadingLista, setLoadingLista] = useState(false);
  const [loadingOpcoes, setLoadingOpcoes] = useState(false);
  const [dados, setDados] = useState<IListarEmplacamentosPR[]>([]);
  const [error, setError] = useState<IHttpResponse<IData | any>>();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [filtro, setFiltro] = useState<IFiltro>({
    empresa: 0,
    divisao: 0,
  });
  const [idsProcessosSelecionados, setIdsProcessosSelecionados] = useState<
    number[]
  >([]);
  const [acaoEmLote, setAcaoEmLote] =
    useState<EtapaGerenciarEmplacamentosEnum | null>(null);
  const [siglaEscolherPlaca, setSiglaEscolherPlaca] = useState<string | null>(
    null
  );
  const [agenteFinanceiroOptions, setAgenteFinanceiroOptions] = useState<
    IOptions[]
  >([]);
  const [agenteFinanceiroEscolhido, setAgenteFinanceiroEscolhido] =
    useState<IAgenteFinanceiro | null>(null);
  const [idProcessoEditado, setIdProcessoEditado] = useState<number | any>(0);
  const [listaAgentesFinanceiros, setListaAgentesFinanceiros] = useState<
    IAgenteFinanceiro[]
  >([]);
  const [idDespachanteDetranEscolhido, setIdDespachanteDetranEscolhido] =
    useState<number>(0);
  const [listaDespachantes, setListaDespachantes] = useState<IDespachante[]>(
    []
  );
  const [despachantesOptions, setDespachantesOptions] = useState<IOptions[]>(
    []
  );
  const [renavamEscolhido, setRenavamEscolhido] = useState<string | null>(null);
  const [idVeiculoEscolhido, setIdVeiculoEscolhido] = useState<number>(0);
  const [placaEscolhida, setPlacaEscolhida] = useState<string | null>(null);
  const [quantidadeJaConcluidaOuPulando, setQuantidadeJaConcluidaOuPulando] = useState<number>(0);
  const [quantidadeTotal, setQuantidadeTotal] = useState<number>(0);

  const gerarQueryParams = (data: IFiltro) => {
    const empresa = data.empresa ? `empresa=${data.empresa}` : null;
    const divisao = data.divisao ? `divisao=${data.divisao}` : null;
    const etapa = data.etapa ? `etapa=${data.etapa}` : null;
    const statusEtapa = data.statusEtapa
      ? `statusEtapa=${data.statusEtapa}`
      : null;

    return [empresa, divisao, etapa, statusEtapa]
      .filter((item) => item !== null)
      .join("&");
  };

  const listar = useCallback(async () => {
    setLoadingLista(true);
    try {
      const queryParams = gerarQueryParams(filtro);
      const { status, data } = await msAw.get(
        `/emplacamento?pagina=${page}&limite=${limit}&${queryParams}`
      );

      setDados(data.itens);
      setTotalRows(data.meta.total);
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      setDados([]);
      setTotalRows(0);
      return response;
    } finally {
      setLoadingLista(false);
    }
  }, [page, limit, filtro]);

  const escolherPlaca = async (idProcesso: number, sigla?: string) => {
    try {
      let dados: { idProcesso: number; sigla?: string } = { idProcesso };

      if (sigla) {
        dados = {
          sigla,
          ...dados,
        };
      }

      const { status, data } = await msAw.post(
        `/emplacamento/escolherPlaca`,
        dados
      );

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    }
  };

  const gerarProcesso = async (idProcesso: number) => {
    try {
      const { status, data } = await msAw.post(`/emplacamento/gerarProcesso`, {
        idProcesso,
      });

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    }
  };

  const pagarTaxa = async (idProcesso: number) => {
    try {
      const { status, data } = await msAw.post(`/emplacamento/pagarTaxa`, {
        idProcesso,
      });

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    }
  };

  const apropriarProcesso = async (idProcesso: number) => {
    try {
      const { status, data } = await msAw.post(
        `/emplacamento/apropriarProcesso`,
        {
          idProcesso,
        }
      );

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    }
  };

  const pagarIpva = async (idProcesso: number) => {
    try {
      const { status, data } = await msAw.post(`/emplacamento/pagarIpva`, {
        idProcesso,
      });

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    }
  };

  const emitirLicenciamento = async (idProcesso: number) => {
    try {
      const { status, data } = await msAw.post(
        `/emplacamento/emitirLicenciamento`,
        {
          idProcesso,
        }
      );

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    }
  };

  const solicitarPlaca = async (idProcesso: number) => {
    try {
      const { status, data } = await msAw.post(`/emplacamento/solicitarPlaca`, {
        idProcesso,
      });

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    }
  };

  const finalizarProcesso = async (idProcesso: number) => {
    try {
      const { status, data } = await msAw.post(
        `/emplacamento/finalizarProcesso`,
        {
          idProcesso,
        }
      );

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    }
  };

  const gerarExcelPedidoPlacas = async (idsProcesso: number[]) => {
    window.open(
      `${
        process.env.REACT_APP_DESPACHANTE_BASE_URL
      }/Gerenciar_Emplacamentos_Excel_Placas.php?ids=${idsProcesso.join(",")}`
    );
  };

  const atualizarProcesso = useCallback(async () => {
    setLoading(true);
    try {
      const obj: IAtualizarProcesso = {};

      if (agenteFinanceiroEscolhido) {
        obj.agente_financeiro_codigo = agenteFinanceiroEscolhido.codigo;
        obj.agente_financeiro_nome = agenteFinanceiroEscolhido.nome;
      }

      if (idDespachanteDetranEscolhido) {
        obj.id_despachante_detran = idDespachanteDetranEscolhido;
      }

      const { status, data } = await api.patch(
        `/processos/${idProcessoEditado}`,
        obj
      );

      const response = new HttpResponse(false, status, data);
      setError(response);
      if (idDespachanteDetranEscolhido) {
        if (!response?.data?.id) {
          toastErro("Erro ao tentar alterar o despachante");
          return response;
        }
        toastSucesso("Despachante alterado", "bottom-right", 8000);
      }
      if (agenteFinanceiroEscolhido) {
        toastSucesso("Agente financeiro alterado", "bottom-right", 8000);
      }
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    } finally {
      setLoading(false);
    }
  }, [
    agenteFinanceiroEscolhido,
    idProcessoEditado,
    idDespachanteDetranEscolhido,
  ]);

  const atualizarDadosVeiculoPorIdVeiculo = useCallback(async () => {
    setLoading(true);
    try {
      if (!idVeiculoEscolhido) {
        toastErro("Erro ao buscar dados para atualizar veículo.");
        return new HttpError<IData>(
          true,
          "Erro ao buscar dados para atualizar veículo."
        );
      }

      const obj: IAtualizarDadosVeiculo = {
        placa: placaEscolhida,
        renavam: renavamEscolhido,
      };

      const { status, data } = await api.patch(
        `/veiculos/${idVeiculoEscolhido}`,
        obj
      );
      const response = new HttpResponse(false, status, data);
      setError(response);
      toastSucesso("Veículo atualizado", "bottom-right", 8000);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    } finally {
      setLoading(false);
    }
  }, [renavamEscolhido, placaEscolhida, idVeiculoEscolhido]);

  const buscarOpcoesAgenteFinanceiro = async () => {
    setLoading(true);
    try {
      const { status, data } = await api.get(`/agentes/`);

      setAgenteFinanceiroOptions(
        data.dados.map((item: IAgenteFinanceiro) => {
          return { value: item.id, label: `${item.codigo} - ${item.nome}` };
        })
      );
      setListaAgentesFinanceiros(data.dados);
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      setAgenteFinanceiroOptions([]);
      setListaAgentesFinanceiros([]);
      return response;
    } finally {
      setLoading(false);
    }
  };

  const buscarOpcoesDespachantes = async () => {
    setLoading(true);
    try {
      const { status, data } = await api.get(`/orgao/`);

      setDespachantesOptions(
        data.map((item: IDespachante) => {
          return { value: item.id, label: item.despachante };
        })
      );
      setListaDespachantes(data);
      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      setDespachantesOptions([]);
      setListaDespachantes([]);
      return response;
    } finally {
      setLoading(false);
    }
  };

  const acoesEmLote = useCallback(async () => {
    setLoadingOpcoes(true);
    try {
      if (!acaoEmLote) {
        return;
      }

      const obj: IGerenciarEmplacamentos = {
        idsProcesso: idsProcessosSelecionados,
        acao: acaoEmLote,
      };

      if (
        siglaEscolherPlaca &&
        acaoEmLote == EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA
      ) {
        obj.sigla = siglaEscolherPlaca;
      }

      const { status, data } = await msAw.post(`/emplacamento/lote`, obj);

      const response = new HttpResponse(false, status, data);
      setError(response);
      await listar();
      toastSucesso("Solicitação recebida", "bottom-right", 8000);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    } finally {
      setLoadingOpcoes(false);
    }
  }, [acaoEmLote, siglaEscolherPlaca, idsProcessosSelecionados]);

  const atualizarProcessoLote = useCallback(async () => {
    setLoadingLista(true);
    try {
      const obj: IAtualizarProcessoLote = {
        idsProcesso: idsProcessosSelecionados,
      };

      if (agenteFinanceiroEscolhido) {
        obj.agente_financeiro_nome = agenteFinanceiroEscolhido.nome;
        obj.agente_financeiro_codigo = agenteFinanceiroEscolhido.codigo;
      }

      if (idDespachanteDetranEscolhido) {
        obj.id_despachante_detran = idDespachanteDetranEscolhido;
      }

      const { status, data } = await msAw.post(
        `/emplacamento/atualizarProcessoLote`,
        obj
      );

      const response = new HttpResponse(false, status, data);
      setError(response);
      await listar();
      if (
        idDespachanteDetranEscolhido &&
        (!data.erros || idsProcessosSelecionados.length > data.erros)
      ) {
        toastSucesso("Despachante alterado", "bottom-right", 8000);
      }
      if (
        agenteFinanceiroEscolhido &&
        (!data.erros || idsProcessosSelecionados.length > data.erros)
      ) {
        toastSucesso("Agente financeiro alterado", "bottom-right", 8000);
      }
      if (data.erros && data.erros >= idsProcessosSelecionados.length) {
        toastErro(
          "Não foi possível atualizar os processos",
          "bottom-right",
          8000
        );
      }
      if (!data.sucesso && data.erros) {
        toastAtencao(
          `Não foi possível atualizar ${data.erros} processo${
            data.erro > 1 ? "s" : ""
          }.`,
          "bottom-right",
          8000
        );
      }
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    } finally {
      setLoadingLista(false);
    }
  }, [
    idsProcessosSelecionados,
    agenteFinanceiroEscolhido,
    idDespachanteDetranEscolhido,
  ]);

  const gerarZipImagensLicenciamento = async (idsProcesso: number[]) => {
    window.open(
      `${
        process.env.REACT_APP_MS_AW
      }/emplacamento/imagens/crlv?ids=${idsProcesso.join(
        ","
      )}&authorization=Bearer ${token}`
    );
  };

  const checarEtapasConcluidas = useCallback(async () => {
    setLoading(true);
    try {
      const { status, data } = await msAw.post(
        `/emplacamento/checarEtapasConcluidas`,
        {
          idsProcesso: idsProcessosSelecionados,
          etapaSelecionada: acaoEmLote,
        }
      );

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    } finally {
      setLoading(false);
    }
  }, [idsProcessosSelecionados, acaoEmLote]);

  const gerarProcessoManualmente = useCallback(
    async (dadosProcesso: IGerarProcessoManualmente) => {
      try {
        const { status, data } = await msAw.post(
          `/emplacamento/gerarProcessoManualmente`,
          {
            idProcesso: idProcessoEditado,
            ...dadosProcesso,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const response = new HttpResponse(false, status, data);
        setError(response);
        return response;
      } catch (error: IHttpError<IData, any> | any) {
        const response = new HttpError<IData>(true, error);
        setError(response);
        return response;
      }
    },
    [idProcessoEditado]
  );

  const escolherPlacaManualmente = useCallback(
    async (dadosProcesso: IEscolherPlacaManualmente) => {
      try {
        const { status, data } = await msAw.post(
          `/emplacamento/escolherPlacaManualmente`,
          {
            idProcesso: idProcessoEditado,
            ...dadosProcesso,
          }
        );

        const response = new HttpResponse(false, status, data);
        setError(response);
        return response;
      } catch (error: IHttpError<IData, any> | any) {
        const response = new HttpError<IData>(true, error);
        setError(response);
        return response;
      }
    },
    [idProcessoEditado]
  );

  const emitirLicenciamentoManualmente = useCallback(
    async (dadosProcesso: IEmitirLicenciamentoManualmente) => {
      try {
        const { status, data } = await msAw.post(
          `/emplacamento/emitirLicenciamentoManualmente`,
          {
            idProcesso: idProcessoEditado,
            ...dadosProcesso,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const response = new HttpResponse(false, status, data);
        setError(response);
        return response;
      } catch (error: IHttpError<IData, any> | any) {
        const response = new HttpError<IData>(true, error);
        setError(response);
        return response;
      }
    },
    [idProcessoEditado]
  );

  const atualizarStatusResumo = async (
    idProcesso: number,
    etapa: EtapaGerenciarEmplacamentosEnum,
    statusEtapa: StatusEtapaGerenciarEmplacamentosEnum
  ) => {
    try {
      const { status, data } = await msAw.post(
        `/emplacamento/atualizarStatusResumo`,
        {
          idProcesso,
          etapa,
          statusEtapa,
        }
      );

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    }
  };

  return {
    listar,
    error,
    totalRows,
    page,
    limit,
    loading,
    dados,
    setDados,
    setPage,
    setLimit,
    setFiltro,
    gerarQueryParams,
    filtro,
    gerarProcesso,
    escolherPlaca,
    pagarIpva,
    pagarTaxa,
    apropriarProcesso,
    emitirLicenciamento,
    solicitarPlaca,
    finalizarProcesso,
    gerarExcelPedidoPlacas,
    acoesEmLote,
    setIdsProcessosSelecionados,
    setAcaoEmLote,
    acaoEmLote,
    setSiglaEscolherPlaca,
    atualizarProcesso,
    agenteFinanceiroOptions,
    setAgenteFinanceiroOptions,
    buscarOpcoesAgenteFinanceiro,
    setAgenteFinanceiroEscolhido,
    setIdProcessoEditado,
    listaAgentesFinanceiros,
    agenteFinanceiroEscolhido,
    idDespachanteDetranEscolhido,
    setIdDespachanteDetranEscolhido,
    buscarOpcoesDespachantes,
    listaDespachantes,
    despachantesOptions,
    setDespachantesOptions,
    renavamEscolhido,
    setRenavamEscolhido,
    idVeiculoEscolhido,
    setIdVeiculoEscolhido,
    placaEscolhida,
    setPlacaEscolhida,
    atualizarDadosVeiculoPorIdVeiculo,
    loadingOpcoes,
    loadingLista,
    idsProcessosSelecionados,
    atualizarProcessoLote,
    gerarZipImagensLicenciamento,
    quantidadeJaConcluidaOuPulando,
    setQuantidadeJaConcluidaOuPulando,
    quantidadeTotal,
    setQuantidadeTotal,
    checarEtapasConcluidas,
    gerarProcessoManualmente,
    idProcessoEditado,
    escolherPlacaManualmente,
    emitirLicenciamentoManualmente,
    atualizarStatusResumo,
  };
}
