import { CompositeContainer } from "components/CompositeContainer";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { IPagamentoTaxa } from "features/core/pages/Financeiro/Interfaces/IPagamentoTaxaLote";
import { BsCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";

export function ListagemResultadoPagamentoLote({
  dados,
}: {
  dados: IPagamentoTaxa[];
}) {
  const columns: IColum<IPagamentoTaxa>[] = [
    {
      name: <span>Id Processo</span>,
      selector: ({ id_processo, linkIdProcessoCriptografado }) => (
        <>
          {linkIdProcessoCriptografado ? (
            <>
              <a
                href={`${process.env.REACT_APP_DESPACHANTE_BASE_URL}/pages/processo/Processo.php?param=${linkIdProcessoCriptografado}`}
                target="_blank"
              >
                {id_processo}
              </a>
            </>
          ) : (
            <>{id_processo}</>
          )}
        </>
      ),

      width: "5%",
    },
    {
      name: <span>CPF / CNPJ</span>,
      selector: ({ cnpj_destinatario }) =>
        cnpj_destinatario
          ? String(cnpj_destinatario).length > 11
            ? String(cnpj_destinatario).replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
                "$1.$2.$3/$4-$5"
              )
            : String(cnpj_destinatario).replace(
                /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
                "$1.$2.$3-$4"
              )
          : "-",
      width: "15%",
    },

    {
      name: <span>Nome Destinatario</span>,
      selector: ({ nome_destinatario }) => nome_destinatario,
      width: "15%",
    },
    {
      name: <span>Pagamento</span>,
      selector: ({ efetou_pagamento }) => (
        <div className="text-center">
          {efetou_pagamento === true ? (
            <BsCheckCircleFill color="green" />
          ) : (
            <BsFillXCircleFill
              style={{
                color: "red",
              }}
            />
          )}{" "}
        </div>
      ),

      width: "10%",
    },
    {
      name: <span>Lancou Custo</span>,
      selector: ({ lancou_custo }) => (
        <div className="text-center">
          {lancou_custo === true ? (
            <BsCheckCircleFill color="green" />
          ) : (
            <BsFillXCircleFill
              style={{
                color: "red",
              }}
            />
          )}{" "}
        </div>
      ),

      width: "10%",
    },
    {
      name: <span>Erros</span>,
      selector: ({ erros }) => erros,
    },
  ];

  return (
    <>
      <CompositeContainer.Root>
        <CompositeContainer.Header>
          <CompositeContainer.Titulo text="Resultado" />
        </CompositeContainer.Header>
        <CompositeContainer.Body>
          <Table
            paginationServer={false}
            columns={columns}
            data={dados}
            loading={false}
            keyField=""
            pagination
            totalRows={dados.length}
            showHeader={false}
            noSubHeader
          />
        </CompositeContainer.Body>
      </CompositeContainer.Root>
    </>
  );
}
