import { ModalCustom } from "components/ModalCustom";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Required } from "components/Required";
import { Controller, useForm } from "react-hook-form";
import temaPadrao from "_config/temas/estilo/base";
import { PrimaryButton } from "components/Button/style";
import { yupResolver } from "@hookform/resolvers/yup";
import { prosseguirManualmenteEscolherPlaca } from "../../validate/validator";
import { ContainerForm, ContainerTexto } from "./style";
import { CustomInput } from "components/CustomInput";
import { IEscolherPlacaManualmente } from "../../Interfaces/IEscolherPlacaManualmente";

interface Props {
  show: boolean;
  handleClose: () => void;
  onSubmit: (dados: IEscolherPlacaManualmente) => void;
}

export function ModalProsseguirManualmenteEscolherPlaca({
  show,
  handleClose,
  onSubmit,
}: Props) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(prosseguirManualmenteEscolherPlaca),
  });

  useEffect(() => {
    reset({
      placa: undefined,
    });
  }, [show]);

  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      title="Prosseguir manualmente"
      centered
    >
      <form
        onSubmit={handleSubmit((dados: IEscolherPlacaManualmente) => {
          onSubmit(dados);
        })}
      >
        <ContainerTexto>
          Informe abaixo o número da placa escolhida
        </ContainerTexto>
        <ContainerForm>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label
                  style={{ color: temaPadrao.colors.neutral.neutral70 }}
                >
                  Placa <Required />
                </Form.Label>
                <Controller
                  name="placa"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      mensagemErro={errors.placa?.message}
                      type="text"
                      placeholder="Digite a placa"
                      style={{ height: "2.2rem" }}
                      value={value}
                      onChange={(value) => {
                        onChange(value);
                      }}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        </ContainerForm>
        <div className="d-flex justify-content-between gap-4 mt-4">
          <PrimaryButton
            style={{ width: "14rem" }}
            outline
            variante="primary"
            onClick={handleClose}
          >
            Cancelar
          </PrimaryButton>
          <PrimaryButton style={{ width: "14rem" }} type="submit">
            Prosseguir
          </PrimaryButton>
        </div>
      </form>
    </ModalCustom>
  );
}
