import styled from "styled-components";

interface ITooltipContent {
  top: number;
  left: number;
  width: number;
  show: boolean;
}

export const TooltipContent = styled.div<ITooltipContent>`
  position: fixed;
  ${({ show }) => (show ? "" : "visibility: hidden;")}
  width: ${({ width }) => width}px;
  z-index: 100;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
`;
