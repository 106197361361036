import { FaTimesCircle } from "react-icons/fa";

interface Props {
  onClick: () => void;
}
export function Erro({ onClick }: Props) {
  return (
    <FaTimesCircle
      onClick={onClick}
      style={{ color: "red", cursor: "pointer" }}
    />
  );
}
