import { LoadingScreen } from "components/LoadingScreen";
import { Page } from "containers/Page";
import { TabsGerenciarEmplacamento } from "./Containers/Tabs";
import { ListarGerenciarEmplacamentos } from "./Containers/Listar";
import { useGerenciarEmplacamentos } from "./Hooks/useGerenciarEmplacamentos";
import { IFiltro } from "./Interfaces/IFiltro";
import { useEffect, useState } from "react";
import { ModalAlterarAgenteFinanceiro } from "./Containers/ModalAlterarAgenteFinanceiro";
import { ModalConfirmarAlterarAgenteFinanceiro } from "./Containers/ModalConfirmarAlterarAgenteFinanceiro";
import { ModalAlterarDespachante } from "./Containers/ModalAlterarDespachante";
import { ModalConfirmarAlterarDespachante } from "./Containers/ModalConfirmarAlterarDespachante";
import { EtapaGerenciarEmplacamentosEnum } from "./enums/EtapaGerenciarEmplacamentos.enum";
import { ModalAlterarRenavam } from "./Containers/ModalAlterarRenavam";
import { ModalAlterarplaca } from "./Containers/ModalAlterarPlaca";
import { ModalConfirmarAlterarEmLote } from "./Containers/ModalConfirmarAlterarEmLote";
import { ModalProsseguirManualmenteGerarProcesso } from "./Containers/ModalProsseguirManualmenteGerarProcesso";
import { IGerarProcessoManualmente } from "./Interfaces/IGerarProcessoManualmente";
import { StatusEtapaGerenciarEmplacamentosEnum } from "./enums/StatusEtapaGerenciarEmplacamentos.enum";
import { ModalErroGerarProcesso } from "./Containers/ModalErroGerarProcesso";
import { ModalErroEscolherPlaca } from "./Containers/ModalErroEscolherPlaca";
import { IEscolherPlacaManualmente } from "./Interfaces/IEscolherPlacaManualmente";
import { ModalProsseguirManualmenteEscolherPlaca } from "./Containers/ModalProsseguirManualmenteEscolherPlaca";
import { ModalErroEmitirLicenciamento } from "./Containers/ModalErroEmitirLicenciamento";
import { IEmitirLicenciamentoManualmente } from "./Interfaces/IEmitirLicenciamentoManualmente";
import { ModalProsseguirManualmenteEmitirLicenciamento } from "./Containers/ModalProsseguirManualmenteEmitirLicenciamento";
import { ModalErroEtapaSemManual } from "./Containers/ModalErroEtapaSemManual";
import { toastErro } from "components/Toast";

export function GerenciarEmplacamentos() {
  const {
    dados,
    error,
    setPage,
    setLimit,
    totalRows,
    loading,
    setFiltro,
    listar,
    filtro,
    page,
    limit,
    gerarProcesso,
    escolherPlaca,
    pagarIpva,
    pagarTaxa,
    apropriarProcesso,
    emitirLicenciamento,
    solicitarPlaca,
    finalizarProcesso,
    setDados,
    gerarExcelPedidoPlacas,
    acoesEmLote,
    setIdsProcessosSelecionados,
    setAcaoEmLote,
    acaoEmLote,
    setSiglaEscolherPlaca,
    atualizarProcesso,
    agenteFinanceiroOptions,
    setAgenteFinanceiroOptions,
    buscarOpcoesAgenteFinanceiro,
    setAgenteFinanceiroEscolhido,
    setIdProcessoEditado,
    listaAgentesFinanceiros,
    agenteFinanceiroEscolhido,
    idDespachanteDetranEscolhido,
    setIdDespachanteDetranEscolhido,
    buscarOpcoesDespachantes,
    listaDespachantes,
    despachantesOptions,
    setDespachantesOptions,
    renavamEscolhido,
    setRenavamEscolhido,
    setIdVeiculoEscolhido,
    placaEscolhida,
    setPlacaEscolhida,
    atualizarDadosVeiculoPorIdVeiculo,
    loadingOpcoes,
    loadingLista,
    idsProcessosSelecionados,
    atualizarProcessoLote,
    gerarZipImagensLicenciamento,
    quantidadeJaConcluidaOuPulando,
    setQuantidadeJaConcluidaOuPulando,
    quantidadeTotal,
    setQuantidadeTotal,
    checarEtapasConcluidas,
    gerarProcessoManualmente,
    idProcessoEditado,
    escolherPlacaManualmente,
    emitirLicenciamentoManualmente,
    atualizarStatusResumo,
  } = useGerenciarEmplacamentos();

  useEffect(() => {
    if (error && error.hasErro) return toastErro(`${error.data.message}`);
  }, [error]);

  const [showOpcoesAcoes, setShowOpcoesAcoes] = useState<boolean>(false);
  const [showOpcoesSolicitarPlaca, setShowOpcoesSolicitarPlaca] =
    useState<boolean>(false);
  const [
    showModalAlterarAgenteFinanceiro,
    setShowModalAlterarAgenteFinanceiro,
  ] = useState<boolean>(false);
  const [tipoSubmit, setTipoSubmit] = useState<"LOTE" | "UNITARIO">("UNITARIO");
  const [
    showModalConfirmarAlterarAgenteFinanceiro,
    setShowModalConfirmarAlterarAgenteFinanceiro,
  ] = useState<boolean>(false);
  const [showModalAlterarDespachante, setShowModalAlterarDespachante] =
    useState<boolean>(false);
  const [
    showModalConfirmarAlterarDespachante,
    setShowModalConfirmarAlterarDespachante,
  ] = useState<boolean>(false);
  const [showModalAlterarRenavam, setShowModalAlterarRenavam] =
    useState<boolean>(false);
  const [showModalAlterarPlaca, setShowModalAlterarPlaca] =
    useState<boolean>(false);
  const [showModalConfirmarAlterarEmLote, setShowModalConfirmarAlterarEmLote] =
    useState<boolean>(false);
  const [
    showModalProsseguirManualmenteGerarProcesso,
    setShowModalProsseguirManualmenteGerarProcesso,
  ] = useState<boolean>(false);
  const [showModalErroGerarProcesso, setShowModalErroGerarProcesso] =
    useState<boolean>(false);
  const [showModalErroEscolherPlaca, setShowModalErroEscolherPlaca] =
    useState<boolean>(false);
  const [
    showModalProsseguirManualmenteEscolherPlaca,
    setShowModalProsseguirManualmenteEscolherPlaca,
  ] = useState<boolean>(false);
  const [
    showModalErroEmitirLicenciamento,
    setShowModalErroEmitirLicenciamento,
  ] = useState<boolean>(false);
  const [
    showModalProsseguirManualmenteEmitirLicenciamento,
    setShowModalProsseguirManualmenteEmitirLicenciamento,
  ] = useState<boolean>(false);
  const [showModalErroEtapaSemManual, setShowModalErroEtapaSemManual] =
    useState<boolean>(false);
  const [mensagemErroEtapa, setMensagemErroEtapa] = useState<string>("");
  const [etapaHandleErro, setEtapaHandleErro] =
    useState<null | EtapaGerenciarEmplacamentosEnum>(null);

  const abrirModalAlterarAgenteFinanceiro = async (
    tipoSubmitEscolhido: "LOTE" | "UNITARIO"
  ) => {
    setTipoSubmit(tipoSubmitEscolhido);
    const { hasErro } = await buscarOpcoesAgenteFinanceiro();

    if (!hasErro) {
      setShowModalAlterarAgenteFinanceiro(true);
    }
  };

  const handleCloseModalAlterarAgenteFinanceiro = () => {
    setShowModalAlterarAgenteFinanceiro(false);
    setAgenteFinanceiroOptions([]);
    setAgenteFinanceiroEscolhido(null);
    setIdProcessoEditado(0);
    setTipoSubmit("UNITARIO");
  };

  const onSubmitUnitarioAlterarAgenteFinanceiro = async () => {
    const { hasErro } = await atualizarProcesso();

    if (!hasErro) {
      handleCloseModalAlterarAgenteFinanceiro();
      listar();
    }
  };

  const onSubmitLoteAlterarAgenteFinanceiro = async () => {
    const { hasErro } = await atualizarProcessoLote();

    if (!hasErro) {
      setShowOpcoesSolicitarPlaca(false);
      setShowOpcoesAcoes(false);
      handleCloseModalAlterarAgenteFinanceiro();
      setShowModalConfirmarAlterarAgenteFinanceiro(false);
    }
  };

  const abrirModalAlterarDespachante = async (
    tipoSubmitEscolhido: "LOTE" | "UNITARIO"
  ) => {
    setTipoSubmit(tipoSubmitEscolhido);
    const { hasErro } = await buscarOpcoesDespachantes();

    if (!hasErro) {
      setShowModalAlterarDespachante(true);
    }
  };

  const handleCloseModalAlterarDespachante = () => {
    setShowModalAlterarDespachante(false);
    setDespachantesOptions([]);
    setIdDespachanteDetranEscolhido(0);
    setIdProcessoEditado(0);
    setTipoSubmit("UNITARIO");
  };

  const onSubmitUnitarioAlterarDespachante = async () => {
    const { hasErro } = await atualizarProcesso();

    if (!hasErro) {
      handleCloseModalAlterarDespachante();
      listar();
    }
  };

  const onSubmitLoteAlterarDespachante = async () => {
    const { hasErro } = await atualizarProcessoLote();

    if (!hasErro) {
      setShowOpcoesSolicitarPlaca(false);
      setShowOpcoesAcoes(false);
      handleCloseModalAlterarDespachante();
      setShowModalConfirmarAlterarDespachante(false);
    }
  };

  const abrirModalAlterarRenavam = async () => {
    setShowModalAlterarRenavam(true);
  };

  const handleCloseModalAlterarRenavam = () => {
    setShowModalAlterarRenavam(false);
    setRenavamEscolhido(null);
    setTipoSubmit("UNITARIO");
    setIdVeiculoEscolhido(0);
    setPlacaEscolhida(null);
  };

  const onSubmitUnitarioAlterarRenavam = async () => {
    const { hasErro } = await atualizarDadosVeiculoPorIdVeiculo();

    if (!hasErro) {
      handleCloseModalAlterarRenavam();
      listar();
    }
  };

  const abrirModalAlterarPlaca = async () => {
    setShowModalAlterarPlaca(true);
  };

  const handleCloseModalAlterarPlaca = () => {
    setShowModalAlterarPlaca(false);
    setPlacaEscolhida(null);
    setTipoSubmit("UNITARIO");
    setIdVeiculoEscolhido(0);
    setRenavamEscolhido(null);
  };

  const onSubmitUnitarioAlterarPlaca = async () => {
    const { hasErro } = await atualizarDadosVeiculoPorIdVeiculo();

    if (!hasErro) {
      handleCloseModalAlterarPlaca();
      listar();
    }
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleLimitChange = (limit: number) => {
    setLimit(limit);
  };

  const handleSubmit = async (dados: IFiltro) => {
    setFiltro(dados);
  };

  useEffect(() => {
    if (filtro.empresa && filtro.divisao) {
      listar();
    }
  }, [page, limit, filtro]);

  const handleAcoesEmLote = async () => {
    if (!acaoEmLote) {
      return;
    }

    const { hasErro, data } = await checarEtapasConcluidas();

    if (hasErro) {
      setQuantidadeJaConcluidaOuPulando(0);
      setQuantidadeTotal(0);
      setAcaoEmLote(null);
      return;
    }

    if (data && data.quantidadeJaConcluidaOuPulando) {
      if (data.quantidadeJaConcluidaOuPulando === data.quantidadeTotal) {
        toastErro(
          "A etapa já foi concluída ou ainda não pode ser finalizada em todos os processos selecionados"
        );
        setQuantidadeJaConcluidaOuPulando(0);
        setQuantidadeTotal(0);
        setAcaoEmLote(null);
        return;
      }
      setQuantidadeJaConcluidaOuPulando(data.quantidadeJaConcluidaOuPulando);
      setQuantidadeTotal(data.quantidadeTotal);
      setShowModalConfirmarAlterarEmLote(true);
      return;
    }

    acoesEmLote();
    setAcaoEmLote(null);
  };

  const closeModalAlterarEmLote = () => {
    setShowModalConfirmarAlterarEmLote(false);
    setQuantidadeJaConcluidaOuPulando(0);
    setQuantidadeTotal(0);
    setAcaoEmLote(null);
  };

  useEffect(() => {
    handleAcoesEmLote();
  }, [acaoEmLote]);

  const selecionarIdsProcesso = (processos: any[]) => {
    setIdsProcessosSelecionados(processos.map((processo) => processo.id));
    if (!processos.length) {
      setShowOpcoesAcoes(false);
      setShowOpcoesSolicitarPlaca(false);
    }
  };

  const onClickSolicitarExcel = async () => {
    await gerarExcelPedidoPlacas(idsProcessosSelecionados);
    setAcaoEmLote(EtapaGerenciarEmplacamentosEnum.SOLICITAR_PLACA);
  };

  const onClickRelatorioExcel = async () => {
    await gerarExcelPedidoPlacas(idsProcessosSelecionados);
  };

  const onClickRelatorioZip = async () => {
    await gerarZipImagensLicenciamento(idsProcessosSelecionados);
  };

  const handleReprocessarEmLote = async () => {
    setAcaoEmLote(EtapaGerenciarEmplacamentosEnum.REPROCESSAR);
  };

  const handleStatus = (
    status: StatusEtapaGerenciarEmplacamentosEnum,
    etapa: EtapaGerenciarEmplacamentosEnum,
    mensagem?: string
  ) => {
    dados.forEach((processo) => {
      if (processo.id == idProcessoEditado) {
        processo.statusEtapa = status;
        processo.etapa = etapa;

        if (mensagem) {
          processo.observacao = mensagem;
        }
      }
    });
    setDados(dados);
  };

  const abrirModalErroGerarProcesso = (id: number, mensagemErro: string) => {
    setIdProcessoEditado(id);
    setMensagemErroEtapa(mensagemErro);
    setShowModalErroGerarProcesso(true);
  };

  const handleCloseModalErroGerarProcesso = () => {
    setShowModalErroGerarProcesso(false);
    setIdProcessoEditado(0);
    setMensagemErroEtapa("");
  };

  const abrirModalProsseguirManualmenteGerarProcesso = () => {
    setShowModalErroGerarProcesso(false);
    setMensagemErroEtapa("");
    setShowModalProsseguirManualmenteGerarProcesso(true);
  };

  const onClickReprocessarModalErroGerarProcesso = async () => {
    handleStatus(
      StatusEtapaGerenciarEmplacamentosEnum.PROCESSANDO,
      EtapaGerenciarEmplacamentosEnum.GERAR_PROCESSO
    );

    setShowModalErroGerarProcesso(false);

    const { hasErro, data } = await gerarProcesso(idProcessoEditado);
    if (!hasErro) {
      handleStatus(
        StatusEtapaGerenciarEmplacamentosEnum.FINALIZADO,
        EtapaGerenciarEmplacamentosEnum.GERAR_PROCESSO
      );
      handleCloseModalErroGerarProcesso();
      return;
    }

    handleStatus(
      StatusEtapaGerenciarEmplacamentosEnum.ERRO,
      EtapaGerenciarEmplacamentosEnum.GERAR_PROCESSO,
      data?.message ?? null
    );
    handleCloseModalErroGerarProcesso();
  };

  const handleCloseModalProsseguirManualmenteGerarProcesso = () => {
    setShowModalProsseguirManualmenteGerarProcesso(false);
    setIdProcessoEditado(0);
    setMensagemErroEtapa("");
  };

  const onSubmitModalProsseguirManualmenteGerarProcesso = async (
    dados: IGerarProcessoManualmente
  ) => {
    handleStatus(
      StatusEtapaGerenciarEmplacamentosEnum.PROCESSANDO,
      EtapaGerenciarEmplacamentosEnum.GERAR_PROCESSO
    );

    setShowModalProsseguirManualmenteGerarProcesso(false);

    const { hasErro, data } = await gerarProcessoManualmente(dados);
    if (!hasErro) {
      handleStatus(
        StatusEtapaGerenciarEmplacamentosEnum.FINALIZADO,
        EtapaGerenciarEmplacamentosEnum.GERAR_PROCESSO
      );
      handleCloseModalProsseguirManualmenteGerarProcesso();
      return;
    }

    handleStatus(
      StatusEtapaGerenciarEmplacamentosEnum.ERRO,
      EtapaGerenciarEmplacamentosEnum.GERAR_PROCESSO,
      data?.message ?? null
    );
    handleCloseModalProsseguirManualmenteGerarProcesso();
  };

  const abrirModalErroEscolherPlaca = (id: number, mensagemErro: string) => {
    setIdProcessoEditado(id);
    setMensagemErroEtapa(mensagemErro);
    setShowModalErroEscolherPlaca(true);
  };

  const handleCloseModalErroEscolherPlaca = () => {
    setShowModalErroEscolherPlaca(false);
    setIdProcessoEditado(0);
    setMensagemErroEtapa("");
  };

  const abrirModalProsseguirManualmenteEscolherPlaca = () => {
    setShowModalErroEscolherPlaca(false);
    setMensagemErroEtapa("");
    setShowModalProsseguirManualmenteEscolherPlaca(true);
  };

  const onClickReprocessarModalErroEscolherPlaca = async () => {
    handleStatus(
      StatusEtapaGerenciarEmplacamentosEnum.PROCESSANDO,
      EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA
    );

    setShowModalErroEscolherPlaca(false);

    const { hasErro, data } = await escolherPlaca(idProcessoEditado);
    if (!hasErro) {
      handleStatus(
        StatusEtapaGerenciarEmplacamentosEnum.FINALIZADO,
        EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA
      );
      handleCloseModalErroEscolherPlaca();
      return;
    }

    handleStatus(
      StatusEtapaGerenciarEmplacamentosEnum.ERRO,
      EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA,
      data?.message ?? null
    );
    handleCloseModalErroEscolherPlaca();
  };

  const handleCloseModalProsseguirManualmenteEscolherPlaca = () => {
    setShowModalProsseguirManualmenteEscolherPlaca(false);
    setIdProcessoEditado(0);
    setMensagemErroEtapa("");
  };

  const onSubmitModalProsseguirManualmenteEscolherPlaca = async (
    dados: IEscolherPlacaManualmente
  ) => {
    handleStatus(
      StatusEtapaGerenciarEmplacamentosEnum.PROCESSANDO,
      EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA
    );

    setShowModalProsseguirManualmenteEscolherPlaca(false);

    const { hasErro, data } = await escolherPlacaManualmente(dados);
    if (!hasErro) {
      handleStatus(
        StatusEtapaGerenciarEmplacamentosEnum.FINALIZADO,
        EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA
      );
      handleCloseModalProsseguirManualmenteEscolherPlaca();
      return;
    }

    handleStatus(
      StatusEtapaGerenciarEmplacamentosEnum.ERRO,
      EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA,
      data?.message ?? null
    );
    handleCloseModalProsseguirManualmenteEscolherPlaca();
  };

  const abrirModalErroEmitirLicenciamento = (
    id: number,
    mensagemErro: string
  ) => {
    setIdProcessoEditado(id);
    setMensagemErroEtapa(mensagemErro);
    setShowModalErroEmitirLicenciamento(true);
  };

  const handleCloseModalErroEmitirLicenciamento = () => {
    setShowModalErroEmitirLicenciamento(false);
    setIdProcessoEditado(0);
    setMensagemErroEtapa("");
  };

  const abrirModalProsseguirManualmenteEmitirLicenciamento = () => {
    setShowModalErroEmitirLicenciamento(false);
    setMensagemErroEtapa("");
    setShowModalProsseguirManualmenteEmitirLicenciamento(true);
  };

  const onClickReprocessarModalErroEmitirLicenciamento = async () => {
    handleStatus(
      StatusEtapaGerenciarEmplacamentosEnum.PROCESSANDO,
      EtapaGerenciarEmplacamentosEnum.EMITIR_LICENCIAMENTO
    );

    setShowModalErroEmitirLicenciamento(false);

    const { hasErro, data } = await emitirLicenciamento(idProcessoEditado);
    if (!hasErro) {
      handleStatus(
        StatusEtapaGerenciarEmplacamentosEnum.FINALIZADO,
        EtapaGerenciarEmplacamentosEnum.EMITIR_LICENCIAMENTO
      );
      handleCloseModalErroEmitirLicenciamento();
      return;
    }

    handleStatus(
      StatusEtapaGerenciarEmplacamentosEnum.ERRO,
      EtapaGerenciarEmplacamentosEnum.EMITIR_LICENCIAMENTO,
      data?.message ?? null
    );
    handleCloseModalErroEmitirLicenciamento();
  };

  const handleCloseModalProsseguirManualmenteEmitirLicenciamento = () => {
    setShowModalProsseguirManualmenteEmitirLicenciamento(false);
    setIdProcessoEditado(0);
    setMensagemErroEtapa("");
  };

  const onSubmitModalProsseguirManualmenteEmitirLicenciamento = async (
    dados: IEmitirLicenciamentoManualmente
  ) => {
    handleStatus(
      StatusEtapaGerenciarEmplacamentosEnum.PROCESSANDO,
      EtapaGerenciarEmplacamentosEnum.EMITIR_LICENCIAMENTO
    );

    setShowModalProsseguirManualmenteEmitirLicenciamento(false);

    const { hasErro, data } = await emitirLicenciamentoManualmente(dados);
    if (!hasErro) {
      handleStatus(
        StatusEtapaGerenciarEmplacamentosEnum.FINALIZADO,
        EtapaGerenciarEmplacamentosEnum.EMITIR_LICENCIAMENTO
      );
      handleCloseModalProsseguirManualmenteEmitirLicenciamento();
      return;
    }

    handleStatus(
      StatusEtapaGerenciarEmplacamentosEnum.ERRO,
      EtapaGerenciarEmplacamentosEnum.EMITIR_LICENCIAMENTO,
      data?.message ?? null
    );
    handleCloseModalProsseguirManualmenteEmitirLicenciamento();
  };

  const abrirModalErroEtapaSemManual = (
    id: number,
    mensagemErro: string,
    etapaComErro: EtapaGerenciarEmplacamentosEnum
  ) => {
    setIdProcessoEditado(id);
    setMensagemErroEtapa(mensagemErro);
    setEtapaHandleErro(etapaComErro);
    setShowModalErroEtapaSemManual(true);
  };

  const handleCloseModalErroEtapaSemManual = () => {
    setShowModalErroEtapaSemManual(false);
    setIdProcessoEditado(0);
    setMensagemErroEtapa("");
    setEtapaHandleErro(null);
  };

  const handleClickOkModalErroEtapaSemManual = () => {
    setShowModalErroEtapaSemManual(false);
    setMensagemErroEtapa("");
    if (etapaHandleErro) {
      atualizarStatusResumo(
        idProcessoEditado,
        etapaHandleErro,
        StatusEtapaGerenciarEmplacamentosEnum.PENDENTE
      );
      handleStatus(
        StatusEtapaGerenciarEmplacamentosEnum.PENDENTE,
        etapaHandleErro
      );
      setEtapaHandleErro(null);
    }
    setIdProcessoEditado(0);
  };

  const onClickReprocessarModalErroEtapaSemManual = async () => {
    if (!etapaHandleErro) {
      return;
    }
    handleStatus(
      StatusEtapaGerenciarEmplacamentosEnum.PROCESSANDO,
      etapaHandleErro
    );

    setShowModalErroEtapaSemManual(false);

    const funcaoDefault = () => {
      return { hasErro: true, data: null };
    };
    const acaoReprocessar = {
      apropriarProcesso: apropriarProcesso,
      finalizarProcesso: finalizarProcesso,
      pagarIpva: pagarIpva,
      pagarTaxa: pagarTaxa,
      solicitarPlaca: solicitarPlaca,
      escolherPlaca: funcaoDefault,
      emitirLicenciamento: funcaoDefault,
      gerarProcesso: funcaoDefault,
      consultarNumeroProcesso: funcaoDefault,
      reprocessar: funcaoDefault,
    };

    if (!acaoReprocessar[etapaHandleErro]) {
      return;
    }

    const { hasErro, data } = await acaoReprocessar[etapaHandleErro](
      idProcessoEditado
    );
    if (!hasErro) {
      handleStatus(
        StatusEtapaGerenciarEmplacamentosEnum.FINALIZADO,
        etapaHandleErro
      );
      handleCloseModalErroEtapaSemManual();
      return;
    }

    handleStatus(
      StatusEtapaGerenciarEmplacamentosEnum.ERRO,
      etapaHandleErro,
      data?.message ?? null
    );
    handleCloseModalErroEtapaSemManual();
  };

  return (
    <Page title="Gerenciar Emplacamento">
      <LoadingScreen mostrar={loading} />
      <TabsGerenciarEmplacamento submit={handleSubmit} />
      <ListarGerenciarEmplacamentos
        dados={dados}
        setDados={setDados}
        handleLimitChange={handleLimitChange}
        handlePageChange={handlePageChange}
        totalRows={totalRows}
        gerarProcesso={gerarProcesso}
        escolherPlaca={escolherPlaca}
        pagarIpva={pagarIpva}
        pagarTaxa={pagarTaxa}
        apropriarProcesso={apropriarProcesso}
        emitirLicenciamento={emitirLicenciamento}
        solicitarPlaca={solicitarPlaca}
        finalizarProcesso={finalizarProcesso}
        gerarExcelPedidoPlacas={gerarExcelPedidoPlacas}
        setRowsSelected={selecionarIdsProcesso}
        setAcaoEmLote={setAcaoEmLote}
        abrirModalAlterarAgenteFinanceiro={abrirModalAlterarAgenteFinanceiro}
        abrirModalAlterarDespachante={abrirModalAlterarDespachante}
        abrirModalAlterarRenavam={abrirModalAlterarRenavam}
        abrirModalAlterarPlaca={abrirModalAlterarPlaca}
        setIdProcessoEditado={setIdProcessoEditado}
        showOpcoesAcoes={showOpcoesAcoes}
        setShowOpcoesAcoes={setShowOpcoesAcoes}
        showOpcoesSolicitarPlaca={showOpcoesSolicitarPlaca}
        setShowOpcoesSolicitarPlaca={setShowOpcoesSolicitarPlaca}
        setIdVeiculoEscolhido={setIdVeiculoEscolhido}
        setRenavamEscolhido={setRenavamEscolhido}
        setPlacaEscolhida={setPlacaEscolhida}
        loadingOpcoes={loadingOpcoes}
        loadingLista={loadingLista}
        onClickSolicitarExcel={onClickSolicitarExcel}
        onClickRelatorioExcel={onClickRelatorioExcel}
        onClickRelatorioZip={onClickRelatorioZip}
        handleReprocessar={handleReprocessarEmLote}
        disabledBotaoAcao={!idsProcessosSelecionados.length}
        abrirModalErroGerarProcesso={abrirModalErroGerarProcesso}
        abrirModalErroEscolherPlaca={abrirModalErroEscolherPlaca}
        abrirModalErroEmitirLicenciamento={abrirModalErroEmitirLicenciamento}
        abrirModalErroEtapaSemManual={abrirModalErroEtapaSemManual}
      />
      <ModalAlterarAgenteFinanceiro
        show={showModalAlterarAgenteFinanceiro}
        handleClose={handleCloseModalAlterarAgenteFinanceiro}
        onSubmitUnitario={onSubmitUnitarioAlterarAgenteFinanceiro}
        onSubmitLote={() => {
          setShowModalAlterarAgenteFinanceiro(false);
          setShowModalConfirmarAlterarAgenteFinanceiro(true);
        }}
        agenteFinanceiroOptions={agenteFinanceiroOptions}
        setAgenteFinanceiro={setAgenteFinanceiroEscolhido}
        tipoSubmit={tipoSubmit}
        listaAgentesFinanceiros={listaAgentesFinanceiros}
        agenteFinanceiroEscolhido={agenteFinanceiroEscolhido}
      />
      <ModalConfirmarAlterarAgenteFinanceiro
        show={showModalConfirmarAlterarAgenteFinanceiro}
        handleClose={() => {
          handleCloseModalAlterarAgenteFinanceiro();
          setShowModalConfirmarAlterarAgenteFinanceiro(false);
        }}
        onSubmit={onSubmitLoteAlterarAgenteFinanceiro}
      />
      <ModalAlterarDespachante
        show={showModalAlterarDespachante}
        handleClose={handleCloseModalAlterarDespachante}
        onSubmitUnitario={onSubmitUnitarioAlterarDespachante}
        onSubmitLote={() => {
          setShowModalAlterarDespachante(false);
          setShowModalConfirmarAlterarDespachante(true);
        }}
        despachanteOptions={despachantesOptions}
        setDespachanteDetranEscolhido={setIdDespachanteDetranEscolhido}
        tipoSubmit={tipoSubmit}
        listaDespachantes={listaDespachantes}
        despachanteDetranEscolhido={idDespachanteDetranEscolhido}
      />
      <ModalConfirmarAlterarDespachante
        show={showModalConfirmarAlterarDespachante}
        handleClose={() => {
          handleCloseModalAlterarDespachante();
          setShowModalConfirmarAlterarDespachante(false);
        }}
        onSubmit={onSubmitLoteAlterarDespachante}
      />
      <ModalAlterarRenavam
        show={showModalAlterarRenavam}
        handleClose={handleCloseModalAlterarRenavam}
        onSubmitUnitario={onSubmitUnitarioAlterarRenavam}
        setRenavamEscolhido={setRenavamEscolhido}
        renavamEscolhido={renavamEscolhido}
      />
      <ModalAlterarplaca
        show={showModalAlterarPlaca}
        handleClose={handleCloseModalAlterarPlaca}
        onSubmitUnitario={onSubmitUnitarioAlterarPlaca}
        setPlacaEscolhida={setPlacaEscolhida}
        placaEscolhida={placaEscolhida}
      />
      <ModalConfirmarAlterarEmLote
        show={showModalConfirmarAlterarEmLote}
        handleClose={closeModalAlterarEmLote}
        onSubmit={() => {
          closeModalAlterarEmLote();
          acoesEmLote();
        }}
        quantidadeJaConcluidaOuPulando={quantidadeJaConcluidaOuPulando}
        quantidadeTotal={quantidadeTotal}
        etapaEscolhida={acaoEmLote}
      />
      <ModalErroGerarProcesso
        show={showModalErroGerarProcesso}
        handleClose={handleCloseModalErroGerarProcesso}
        onClickOk={abrirModalProsseguirManualmenteGerarProcesso}
        mensagemErro={mensagemErroEtapa}
        onClickReprocessar={onClickReprocessarModalErroGerarProcesso}
      />
      <ModalProsseguirManualmenteGerarProcesso
        show={showModalProsseguirManualmenteGerarProcesso}
        onSubmit={onSubmitModalProsseguirManualmenteGerarProcesso}
        handleClose={handleCloseModalProsseguirManualmenteGerarProcesso}
      />
      <ModalErroEscolherPlaca
        show={showModalErroEscolherPlaca}
        handleClose={handleCloseModalErroEscolherPlaca}
        onClickOk={abrirModalProsseguirManualmenteEscolherPlaca}
        mensagemErro={mensagemErroEtapa}
        onClickReprocessar={onClickReprocessarModalErroEscolherPlaca}
      />
      <ModalProsseguirManualmenteEscolherPlaca
        show={showModalProsseguirManualmenteEscolherPlaca}
        onSubmit={onSubmitModalProsseguirManualmenteEscolherPlaca}
        handleClose={handleCloseModalProsseguirManualmenteEscolherPlaca}
      />
      <ModalErroEmitirLicenciamento
        show={showModalErroEmitirLicenciamento}
        handleClose={handleCloseModalErroEmitirLicenciamento}
        onClickOk={abrirModalProsseguirManualmenteEmitirLicenciamento}
        mensagemErro={mensagemErroEtapa}
        onClickReprocessar={onClickReprocessarModalErroEmitirLicenciamento}
      />
      <ModalProsseguirManualmenteEmitirLicenciamento
        show={showModalProsseguirManualmenteEmitirLicenciamento}
        onSubmit={onSubmitModalProsseguirManualmenteEmitirLicenciamento}
        handleClose={handleCloseModalProsseguirManualmenteEmitirLicenciamento}
      />
      <ModalErroEtapaSemManual
        show={showModalErroEtapaSemManual}
        handleClose={handleCloseModalErroEtapaSemManual}
        onClickOk={handleClickOkModalErroEtapaSemManual}
        mensagemErro={mensagemErroEtapa}
        onClickReprocessar={onClickReprocessarModalErroEtapaSemManual}
      />
    </Page>
  );
}
