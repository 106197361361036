import { ModalCustom } from "components/ModalCustom";
import { PrimaryButton } from "components/Button/style";
import { IconeContainer, InfoContainer, TextContainer } from "./style";
import { FaExclamation } from "react-icons/fa";
import { EtapaGerenciarEmplacamentosEnum } from "../../enums/EtapaGerenciarEmplacamentos.enum";

interface Props {
  show: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  quantidadeJaConcluidaOuPulando: number;
  quantidadeTotal: number;
  etapaEscolhida: EtapaGerenciarEmplacamentosEnum | null;
}

export function ModalConfirmarAlterarEmLote({
  show,
  handleClose,
  onSubmit,
  quantidadeJaConcluidaOuPulando,
  quantidadeTotal,
  etapaEscolhida,
}: Props) {
  const strategyEtapa = [
    {
      etapa: EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA,
      nome: "Escolher placa",
    },
    {
      etapa: EtapaGerenciarEmplacamentosEnum.PAGAR_TAXA,
      nome: "Pagar taxa",
    },
    {
      etapa: EtapaGerenciarEmplacamentosEnum.GERAR_PROCESSO,
      nome: "Gerar processo",
    },
    {
      etapa: EtapaGerenciarEmplacamentosEnum.PAGAR_IPVA,
      nome: "Pagar IPVA",
    },
    {
      etapa: EtapaGerenciarEmplacamentosEnum.EMITIR_LICENCIAMENTO,
      nome: "Emitir licenciamento",
    },
    {
      etapa: EtapaGerenciarEmplacamentosEnum.SOLICITAR_PLACA,
      nome: "Solicitar placa",
    },
    {
      etapa: EtapaGerenciarEmplacamentosEnum.FINALIZAR_PROCESSO,
      nome: "Finalizar processo",
    },
    {
      etapa: EtapaGerenciarEmplacamentosEnum.APROPRIAR_PROCESSO,
      nome: "Apropriar processo",
    },
  ];

  const obterNomeEtapa = () => {
    const nome = strategyEtapa.filter(
      (etapa) => etapa.etapa === etapaEscolhida
    );

    if (nome.length) {
      return nome[0].nome;
    }

    return "selecionada";
  };

  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      title={obterNomeEtapa()}
      centered
    >
      <InfoContainer>
        <IconeContainer>
          <FaExclamation />
        </IconeContainer>
        <TextContainer>
          A etapa <strong>{obterNomeEtapa()}</strong> já foi finalizada ou ainda
          não pode ser finalizada em {quantidadeJaConcluidaOuPulando} dos{" "}
          {quantidadeTotal} processos selecionados. Deseja prosseguir com a
          solicitação somente para os processos que estão com a etapa pendente?
        </TextContainer>
      </InfoContainer>
      <div className="d-flex justify-content-between gap-4 mt-4">
        <PrimaryButton
          style={{ width: "14rem" }}
          outline
          variante="primary"
          onClick={handleClose}
        >
          Cancelar
        </PrimaryButton>
        <PrimaryButton style={{ width: "14rem" }} onClick={onSubmit}>
          Prosseguir
        </PrimaryButton>
      </div>
    </ModalCustom>
  );
}
