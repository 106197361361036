import { Tabs } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 1.5rem;
  width: 100%;
`;

export const CustomTabs = styled(Tabs)`
  .nav-link {
    color: ${({ theme }) => theme.colors.neutral.neutral80} !important;
  }
`;

export const ContainerFiltros = styled.div`
  width: 100%;
  padding: 32px;
  background-color: ${({ theme }) => theme.colors.white};
`;
