import { ModalCustom } from "components/ModalCustom";
import { PrimaryButton } from "components/Button/style";
import { IconeContainer, InfoContainer, TextContainer } from "./style";
import { FaRedo, FaTimes } from "react-icons/fa";

interface Props {
  show: boolean;
  handleClose: () => void;
  onClickOk: () => void;
  onClickReprocessar: () => void;
  mensagemErro: string | null;
}

export function ModalErroEmitirLicenciamento({
  show,
  handleClose,
  onClickOk,
  onClickReprocessar,
  mensagemErro,
}: Props) {
  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      title="Erro - Emitir licenciamento"
      centered
    >
      <InfoContainer>
        <IconeContainer>
          <FaTimes />
        </IconeContainer>
        <TextContainer>
          {mensagemErro ?? "Erro ao emitir licenciamento."}
        </TextContainer>
      </InfoContainer>
      <div className="d-flex justify-content-end gap-4 mt-4">
        <PrimaryButton
          style={{ width: "14rem" }}
          outline
          variante="primary"
          onClick={onClickOk}
        >
          Prosseguir manualmente
        </PrimaryButton>
        <PrimaryButton style={{ width: "10rem" }} onClick={onClickReprocessar}>
          Reprocessar <FaRedo />
        </PrimaryButton>
      </div>
    </ModalCustom>
  );
}
