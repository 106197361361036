import { ModalCustom } from "components/ModalCustom";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Required } from "components/Required";
import { Controller, useForm } from "react-hook-form";
import temaPadrao from "_config/temas/estilo/base";
import { PrimaryButton } from "components/Button/style";
import { yupResolver } from "@hookform/resolvers/yup";
import { alterarAgenteFinanceiro } from "../../validate/validator";
import { DropdownSelect, IOptions } from "components/DropdownSelect";
import { ContainerForm } from "./style";
import { IAgenteFinanceiro } from "../../Interfaces/IAgenteFinanceiro";

interface Props {
  show: boolean;
  handleClose: () => void;
  onSubmitUnitario: () => void;
  onSubmitLote: () => void;
  agenteFinanceiroOptions: IOptions[];
  setAgenteFinanceiro: (agente: null | any) => void;
  tipoSubmit: "LOTE" | "UNITARIO";
  listaAgentesFinanceiros: IAgenteFinanceiro[];
  agenteFinanceiroEscolhido: IAgenteFinanceiro | null;
}

export function ModalAlterarAgenteFinanceiro({
  show,
  handleClose,
  onSubmitUnitario,
  onSubmitLote,
  agenteFinanceiroOptions,
  setAgenteFinanceiro,
  tipoSubmit,
  listaAgentesFinanceiros,
  agenteFinanceiroEscolhido,
}: Props) {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(alterarAgenteFinanceiro),
  });

  useEffect(() => {
    reset({
      agenteFinanceiro: undefined,
    });
    setValue("agenteFinanceiro", 0);
  }, [show]);

  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      title="Alterar Agente Financeiro"
      centered
    >
      <form
        onSubmit={handleSubmit(() => {
          if (tipoSubmit === "UNITARIO") {
            onSubmitUnitario();
            return;
          }
          onSubmitLote();
        })}
      >
        <ContainerForm>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label
                  style={{ color: temaPadrao.colors.neutral.neutral70 }}
                >
                  Agente financeiro <Required />
                </Form.Label>
                <Controller
                  control={control}
                  name="agenteFinanceiro"
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      options={agenteFinanceiroOptions}
                      title=""
                      selectedValue={value}
                      onSelect={(value) => {
                        onChange(value);
                        if (!value) {
                          setAgenteFinanceiro(null);
                          return;
                        }
                        const agente = listaAgentesFinanceiros.filter(
                          (agente) => agente.id == value
                        );

                        if (agente.length) {
                          setAgenteFinanceiro(agente[0]);
                          return;
                        }

                        setAgenteFinanceiro(null);
                      }}
                      searchable
                      gapDropdownContainer="0"
                      required
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        </ContainerForm>
        <div className="d-flex justify-content-between gap-4 mt-4">
          <PrimaryButton
            style={{ width: "14rem" }}
            outline
            variante="primary"
            onClick={handleClose}
          >
            Cancelar
          </PrimaryButton>
          <PrimaryButton
            style={{ width: "14rem" }}
            type="submit"
            disabled={!agenteFinanceiroEscolhido}
          >
            Salvar
          </PrimaryButton>
        </div>
      </form>
    </ModalCustom>
  );
}
