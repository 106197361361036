import { CSSProperties, useState } from "react";
import { FaPen } from "react-icons/fa";

interface IIconeEditar {
  texto: string;
  widthCell?: number;
  maxWidthTexto?: number;
  onClick: () => void;
}

export function IconeEditar({
  texto,
  widthCell = 220,
  maxWidthTexto = 220,
  onClick,
}: IIconeEditar) {
  const styleHovered: CSSProperties = {
    width: `${widthCell}px`,
    marginRight: "5px",
    position: "relative",
  };

  const styleNormal: CSSProperties = {
    ...styleHovered,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  const [style, setStyle] = useState<CSSProperties>(styleNormal);

  return (
    <span
      style={{
        display: "inline-flex",
        alignItems: "center",
        maxWidth: `${maxWidthTexto}px`,
      }}
    >
      <div
        style={style}
        onMouseEnter={() => setStyle(styleHovered)}
        onMouseLeave={() => setStyle(styleNormal)}
      >
        {texto}
      </div>
      <div>{<FaPen onClick={onClick} style={{ cursor: "pointer" }} />}</div>
    </span>
  );
}
