import styled from "styled-components";
import 'react-datepicker/dist/react-datepicker.css';
import { PrimaryButton } from "components/Button/style";
import { Col } from "react-bootstrap";

export const FormContainer = styled.div`

  select {
    border-radius: 8px;
    height: 38px;
  }
`;

export const InputContainer = styled(Col)`
  display: grid;
`;

export const Button = styled(PrimaryButton)`
  width: 100px;
`;

export const ButtonsContainer = styled.div`
  position: relative;
  padding-top: 1.7rem;
`;