export enum EtapaGerenciarEmplacamentosEnum {
  ESCOLHER_PLACA = "escolherPlaca",
  PAGAR_TAXA = "pagarTaxa",
  GERAR_PROCESSO = "gerarProcesso",
  PAGAR_IPVA = "pagarIpva",
  CONSULTAR_NUMERO_PROCESSO = "consultarNumeroProcesso",
  EMITIR_LICENCIAMENTO = "emitirLicenciamento",
  SOLICITAR_PLACA = "solicitarPlaca",
  FINALIZAR_PROCESSO = "finalizarProcesso",
  APROPRIAR_PROCESSO = "apropriarProcesso",
  REPROCESSAR = "reprocessar",
}
