import { Tab } from "react-bootstrap";
import { Container, ContainerFiltros, CustomTabs } from "./style";
import { useState } from "react";
import { FiltrosPR } from "../FiltrosPR";
import { IFiltro } from "../../Interfaces/IFiltro";

interface IProps {
  submit: (dados: IFiltro) => void;
}
export function TabsGerenciarEmplacamento({ submit }: IProps) {
  const [aba, setAba] = useState<string>("emplacamentoPR");

  const onSelectAba = (e: string | null) => {
    if (e === "emplacamentoPR") {
      setAba(e);
      return;
    }

    window.location.href = `${process.env.REACT_APP_DESPACHANTE_BASE_URL}/Gerenciar_Emplacamentos.php?uf=sp`;
  };

  return (
    <Container>
      <CustomTabs
        defaultActiveKey="emplacamentoPR"
        activeKey={aba}
        onSelect={onSelectAba}
      >
        <Tab
          eventKey="emplacamentoPR"
          title="Emplacamento PR"
          style={{ color: "black" }}
        >
          <ContainerFiltros>
            <FiltrosPR submitForm={submit} />
          </ContainerFiltros>
        </Tab>
        <Tab
          eventKey="emplacamentoSP"
          title="Emplacamento SP"
          style={{
            color: "black !important",
            backgroundColor: "red !important",
          }}
        >
          <ContainerFiltros>Tab content for Profile</ContainerFiltros>
        </Tab>
      </CustomTabs>
    </Container>
  );
}
