import { Erro } from "../StatusIcones/erro";
import { Finalizado } from "../StatusIcones/finalizado";
import { Processando } from "../StatusIcones/processando";
import { EtapaGerenciarEmplacamentosEnum } from "../../enums/EtapaGerenciarEmplacamentos.enum";
import { StatusEtapaGerenciarEmplacamentosEnum } from "../../enums/StatusEtapaGerenciarEmplacamentos.enum";
import { Indisponivel } from "../StatusIcones/indisponivel";
import { HttpResponse } from "_services/api/HttpResponse";
import { IListarEmplacamentosPR } from "../../Interfaces/IListarEmplacamentosPR";
import { Carro } from "../StatusIcones/carro";

interface HandleStatusSolicitarPlacaProps {
  etapa: EtapaGerenciarEmplacamentosEnum;
  status: StatusEtapaGerenciarEmplacamentosEnum;
  solicitarPlaca: (id: number) => Promise<HttpResponse>;
  idProcesso: number;
  dados: IListarEmplacamentosPR[];
  setDados: (dados: IListarEmplacamentosPR[]) => void;
  gerarExcelPedidoPlacas: (ids: number[]) => any;
  setTooltipLeft: (left: number) => void;
  setTooltipTop: (top: number) => void;
  setTooltipConteudo: (conteudo: string | JSX.Element) => void;
  setTooltipWidth: (width: number) => void;
  setShowTooltip: (show: boolean) => void;
  abrirModalErroEtapaSemManual: (
    id: number,
    mensagemErro: string,
    etapaComErro: EtapaGerenciarEmplacamentosEnum
  ) => void;
  mensagemErro: string;
  codigoDeBarras: NullableString;
}
export function HandleStatusSolicitarPlaca({
  etapa,
  status,
  solicitarPlaca,
  idProcesso,
  dados,
  setDados,
  gerarExcelPedidoPlacas,
  setTooltipLeft,
  setTooltipTop,
  setTooltipConteudo,
  setTooltipWidth,
  setShowTooltip,
  abrirModalErroEtapaSemManual,
  mensagemErro,
  codigoDeBarras,
}: HandleStatusSolicitarPlacaProps) {
  const handleStatus = (
    status: StatusEtapaGerenciarEmplacamentosEnum,
    mensagem?: string
  ) => {
    dados.forEach((processo) => {
      if (processo.id == idProcesso) {
        processo.statusEtapa = status;
        processo.etapa = EtapaGerenciarEmplacamentosEnum.SOLICITAR_PLACA;

        if (mensagem) {
          processo.observacao = mensagem;
        }
      }
    });
    setDados(dados);
  };

  const onClick = async () => {
    handleStatus(StatusEtapaGerenciarEmplacamentosEnum.PROCESSANDO);
    setDados([...dados]);
    await gerarExcelPedidoPlacas([idProcesso]);

    setShowTooltip(false);
    setTooltipLeft(0);
    setTooltipTop(0);
    setTooltipConteudo("");

    const { hasErro, data } = await solicitarPlaca(idProcesso);
    if (hasErro) {
      handleStatus(
        StatusEtapaGerenciarEmplacamentosEnum.ERRO,
        data?.message ?? null
      );
      return;
    }

    handleStatus(StatusEtapaGerenciarEmplacamentosEnum.FINALIZADO);
  };

  const etapasPosteriores = [
    EtapaGerenciarEmplacamentosEnum.FINALIZAR_PROCESSO,
  ];

  const etapasMuitoAnteriores = [
    EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA,
    EtapaGerenciarEmplacamentosEnum.GERAR_PROCESSO,
    EtapaGerenciarEmplacamentosEnum.PAGAR_TAXA,
    EtapaGerenciarEmplacamentosEnum.APROPRIAR_PROCESSO,
    EtapaGerenciarEmplacamentosEnum.PAGAR_IPVA,
  ];

  if (
    !codigoDeBarras ||
    !etapa ||
    (etapa == EtapaGerenciarEmplacamentosEnum.EMITIR_LICENCIAMENTO &&
      status != StatusEtapaGerenciarEmplacamentosEnum.FINALIZADO) ||
    etapasMuitoAnteriores.includes(etapa)
  ) {
    return <Indisponivel />;
  }

  if (
    (etapa && etapasPosteriores.includes(etapa)) ||
    (etapa == EtapaGerenciarEmplacamentosEnum.SOLICITAR_PLACA &&
      status == StatusEtapaGerenciarEmplacamentosEnum.FINALIZADO)
  ) {
    return <Finalizado />;
  }

  if (
    etapa == EtapaGerenciarEmplacamentosEnum.SOLICITAR_PLACA &&
    status == StatusEtapaGerenciarEmplacamentosEnum.ERRO
  ) {
    return (
      <Erro
        onClick={() =>
          abrirModalErroEtapaSemManual(
            idProcesso,
            mensagemErro,
            EtapaGerenciarEmplacamentosEnum.SOLICITAR_PLACA
          )
        }
      />
    );
  }

  if (
    etapa == EtapaGerenciarEmplacamentosEnum.SOLICITAR_PLACA &&
    status == StatusEtapaGerenciarEmplacamentosEnum.PROCESSANDO
  ) {
    return (
      <Processando
        setTooltipLeft={setTooltipLeft}
        setTooltipTop={setTooltipTop}
        setTooltipConteudo={setTooltipConteudo}
        setTooltipWidth={setTooltipWidth}
        setShowTooltip={setShowTooltip}
      />
    );
  }

  return <Carro acao={onClick} />;
}
