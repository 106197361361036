import { Consultando } from "components/Consultando";

interface Props {
  setTooltipLeft: (left: number) => void;
  setTooltipTop: (top: number) => void;
  setTooltipConteudo: (conteudo: string | JSX.Element) => void;
  setTooltipWidth: (width: number) => void;
  setShowTooltip: (show: boolean) => void;
}
export function Processando({
  setTooltipLeft,
  setTooltipTop,
  setTooltipConteudo,
  setTooltipWidth,
  setShowTooltip,
}: Props) {
  return (
    <span
      onMouseEnter={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        const { left, top } = event.currentTarget.getBoundingClientRect();
        setTooltipLeft(left);
        setTooltipTop(top);
        setTooltipConteudo("Processando");
        setTooltipWidth(95);
        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
        setTooltipLeft(0);
        setTooltipTop(0);
        setTooltipConteudo("");
      }}
    >
      <Consultando />
    </span>
  );
}
