import { EtapaGerenciarEmplacamentosEnum } from "../../enums/EtapaGerenciarEmplacamentos.enum";
import { StatusEtapaGerenciarEmplacamentosEnum } from "../../enums/StatusEtapaGerenciarEmplacamentos.enum";
import { Bloqueado } from "../StatusIcones/bloqueado";
import { Finalizado } from "../StatusIcones/finalizado";
import { Erro } from "../StatusIcones/erro";
import { Carro } from "../StatusIcones/carro";
import { Processando } from "../StatusIcones/processando";
import { HttpResponse } from "_services/api/HttpResponse";
import { IListarEmplacamentosPR } from "../../Interfaces/IListarEmplacamentosPR";

interface HandleStatusEscolherPlacaProps {
  habilitar: boolean;
  etapa: EtapaGerenciarEmplacamentosEnum;
  status: StatusEtapaGerenciarEmplacamentosEnum;
  escolherPlaca: (id: number, sigla?: string) => Promise<HttpResponse>;
  idProcesso: number;
  dados: IListarEmplacamentosPR[];
  setDados: (dados: IListarEmplacamentosPR[]) => void;
  setTooltipLeft: (left: number) => void;
  setTooltipTop: (top: number) => void;
  setTooltipConteudo: (conteudo: string | JSX.Element) => void;
  setTooltipWidth: (width: number) => void;
  setShowTooltip: (show: boolean) => void;
  abrirModalErroEscolherPlaca: (id: number, mensagemErro: string) => void;
  mensagemErro: string;
}
export function HandleStatusEscolherPlaca({
  habilitar,
  etapa,
  status,
  escolherPlaca,
  idProcesso,
  dados,
  setDados,
  setTooltipLeft,
  setTooltipTop,
  setTooltipConteudo,
  setTooltipWidth,
  setShowTooltip,
  abrirModalErroEscolherPlaca,
  mensagemErro,
}: HandleStatusEscolherPlacaProps) {
  const handleStatus = (
    status: StatusEtapaGerenciarEmplacamentosEnum,
    mensagem?: string
  ) => {
    dados.forEach((processo) => {
      if (processo.id == idProcesso) {
        processo.statusEtapa = status;
        processo.etapa = EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA;

        if (mensagem) {
          processo.observacao = mensagem;
        }
      }
    });
    setDados(dados);
  };

  const onClick = async () => {
    handleStatus(StatusEtapaGerenciarEmplacamentosEnum.PROCESSANDO);
    setDados([...dados]);
    const { hasErro, data } = await escolherPlaca(idProcesso);

    setShowTooltip(false);
    setTooltipLeft(0);
    setTooltipTop(0);
    setTooltipConteudo("");

    if (hasErro) {
      handleStatus(
        StatusEtapaGerenciarEmplacamentosEnum.ERRO,
        data?.message ?? null
      );
      return;
    }

    handleStatus(StatusEtapaGerenciarEmplacamentosEnum.FINALIZADO);
  };

  if (!habilitar) {
    return <Bloqueado />;
  }

  if (
    (etapa && etapa != EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA) ||
    (etapa == EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA &&
      status == StatusEtapaGerenciarEmplacamentosEnum.FINALIZADO)
  ) {
    return <Finalizado />;
  }

  if (
    etapa == EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA &&
    status == StatusEtapaGerenciarEmplacamentosEnum.ERRO
  ) {
    return (
      <Erro
        onClick={() => abrirModalErroEscolherPlaca(idProcesso, mensagemErro)}
      />
    );
  }

  if (
    etapa == EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA &&
    status == StatusEtapaGerenciarEmplacamentosEnum.PROCESSANDO
  ) {
    return (
      <Processando
        setTooltipLeft={setTooltipLeft}
        setTooltipTop={setTooltipTop}
        setTooltipConteudo={setTooltipConteudo}
        setTooltipWidth={setTooltipWidth}
        setShowTooltip={setShowTooltip}
      />
    );
  }

  return <Carro acao={onClick} />;
}
