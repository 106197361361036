import { Route, Routes } from "react-router-dom";
import { PreenchimentoATPV } from "../Pages/PreenchimentoATPV";
import { PreenchimentoATPVDetalhes } from "../Pages/PreenchimentoATPVDetalhes";
import { AutorizacaoPlaca } from "../Pages/AutorizacaoPlaca";
import { AutorizacaoPlacaPage } from "../Pages/AutorizacaoPlaca/context/AutorizacaoPlacaContext";
import { GerenciarEmplacamentos } from "../Pages/GerenciarEmplacamentos";

function BackofficeRoutes() {
  return (
    <Routes>
      <Route path="/preenchimentoatpv" element={<PreenchimentoATPV />} />
      <Route
        path="/preenchimentoatpv/detalhes"
        element={<PreenchimentoATPVDetalhes />}
      />
      <Route
        path="/autorizaPlaca"
        element={
          <AutorizacaoPlacaPage>
            <AutorizacaoPlaca />
          </AutorizacaoPlacaPage>
        }
      />
      <Route path="/gerenciaremplacamento" element={<GerenciarEmplacamentos />} />
    </Routes>
  );
}
export { BackofficeRoutes };
