import { ReactNode } from "react";
import { TooltipContent } from "./style";

interface ITooltip {
  children: ReactNode;
  show: boolean;
  top: number;
  left: number;
  width: number;
  height?: number;
  cantoReferencia?:
    | "superiorDireito"
    | "superiorEsquerdo"
    | "inferiorDireito"
    | "inferiorEsquerdo";
  margem?: number;
}

export function TooltipV2({
  children,
  show,
  width,
  top,
  left,
  cantoReferencia = "superiorDireito",
  margem = 7,
  height = 0,
}: ITooltip) {
  const strategyPosicao = {
    superiorDireito: { left: left - width - margem, top: top + 2 * margem },
    superiorEsquerdo: {
      left: left + 2 * margem,
      top: top + 2 * margem,
    },
    inferiorDireito: {
      left: left - width - margem,
      top: top - height - margem,
    },
    inferiorEsquerdo: { left: left + 2 * margem, top: top - height - margem },
  };
  return (
    <TooltipContent
      top={strategyPosicao[cantoReferencia].top}
      left={strategyPosicao[cantoReferencia].left}
      width={width}
      show={show}
    >
      {children}
    </TooltipContent>
  );
}
