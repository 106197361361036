import { ExclamationTriangleFill } from "react-bootstrap-icons";

interface IProps {
  onClick: (id: number, mensagemErro: string) => void;
  id: number;
  mensagem: string;
}
export function Atencao({ onClick, id, mensagem }: IProps) {
  return (
    <ExclamationTriangleFill
      onClick={() => onClick(id, mensagem)}
      style={{
        cursor: "pointer",
        color: "orange",
      }}
    />
  );
}
