import { Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { DropdownSelect } from "components/DropdownSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  ButtonsContainer,
  FormContainer,
  InputContainer,
} from "./style";
import { useEmpresas } from "hooks/useEmpresas";
import { useState } from "react";
import { gerenciarEmplacamentoFiltroPR } from "../../validate/validator";
import { IFiltroPR } from "../../Interfaces/IFiltroPR";
import { useDivisoes } from "hooks/useDivisoes";
import { EtapaGerenciarEmplacamentosEnum } from "../../enums/EtapaGerenciarEmplacamentos.enum";

interface IFiltrosProps {
  submitForm: (e: IFiltroPR) => void;
}

export function FiltrosPR({ submitForm }: IFiltrosProps) {
  const { register, handleSubmit, reset, control } = useForm({
    resolver: yupResolver(gerenciarEmplacamentoFiltroPR),
  });

  const [idEmpresaEscolhida, setIdEmpresaEscolhida] = useState<number>(0);
  const [desabilitarDivisao, setDesabilitarDivisao] = useState<boolean>(true);
  const [desabilitarStatus, setDesabilitarStatus] = useState<boolean>(true);
  const [desabilitarBuscar, setDesabilitarBuscar] = useState<boolean>(true);
  const onChangeEmpresaEscolhida = (empresa: number | null) => {
    if (empresa) {
      setIdEmpresaEscolhida(empresa);
      setDesabilitarDivisao(false);
      return;
    }

    setIdEmpresaEscolhida(0);
    setDesabilitarDivisao(true);
  };

  const { empresasOptions } = useEmpresas();
  const { divisoesOptions } = useDivisoes(idEmpresaEscolhida);
  const onChangeEtapa = (etapa: string) => {
    if (etapa) {
      setDesabilitarStatus(false);
      return;
    }

    setDesabilitarStatus(true);
  };

  return (
    <FormContainer>
      <Form
        noValidate
        onSubmit={handleSubmit((dados: IFiltroPR) => submitForm(dados))}
      >
        <Row>
          <InputContainer>
            <Form.Group>
              <Form.Label>Empresa</Form.Label>
              <Controller
                control={control}
                name="empresa"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={empresasOptions}
                    title=""
                    selectedValue={value}
                    onSelect={(value) => {
                      onChange(value);
                      setDesabilitarBuscar(true);
                      if (!value) {
                        onChangeEmpresaEscolhida(0);
                        return;
                      }
                      onChangeEmpresaEscolhida(value);
                    }}
                    searchable
                    required
                  />
                )}
              />
            </Form.Group>
          </InputContainer>
          <InputContainer>
            <Form.Group>
              <Form.Label>Divisão</Form.Label>
              <Controller
                control={control}
                name="divisao"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={divisoesOptions}
                    title=""
                    selectedValue={value}
                    onSelect={(value) => {
                      onChange(value);
                      if (!value) {
                        setDesabilitarBuscar(true);
                        return;
                      }
                      setDesabilitarBuscar(false);
                    }}
                    searchable
                    disabled={desabilitarDivisao}
                    gapDropdownContainer="0"
                    required
                  />
                )}
              />
            </Form.Group>
          </InputContainer>
          <InputContainer>
            <Form.Group>
              <Form.Label>Etapa</Form.Label>
              <Controller
                control={control}
                name="etapa"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={[
                      {
                        value: EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA,
                        label: "Escolher placa",
                      },
                      {
                        value: EtapaGerenciarEmplacamentosEnum.GERAR_PROCESSO,
                        label: "Gerar processo",
                      },
                      {
                        value: EtapaGerenciarEmplacamentosEnum.PAGAR_TAXA,
                        label: "Pagar taxa",
                      },
                      {
                        value:
                          EtapaGerenciarEmplacamentosEnum.APROPRIAR_PROCESSO,
                        label: "Apropriar processo",
                      },
                      {
                        value: EtapaGerenciarEmplacamentosEnum.PAGAR_IPVA,
                        label: "Pagar IPVA",
                      },
                      {
                        value:
                          EtapaGerenciarEmplacamentosEnum.EMITIR_LICENCIAMENTO,
                        label: "Emitir licenciamento",
                      },
                      {
                        value: EtapaGerenciarEmplacamentosEnum.SOLICITAR_PLACA,
                        label: "Solicitar placa",
                      },
                    ]}
                    title=""
                    selectedValue={value}
                    onSelect={(value) => {
                      onChange(value);
                      onChangeEtapa(value);
                    }}
                  />
                )}
              />
            </Form.Group>
          </InputContainer>
          <InputContainer>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Controller
                control={control}
                name="statusEtapa"
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={[
                      { value: "finalizado", label: "Finalizado" },
                      { value: "pendente", label: "Pendente" },
                      { value: "processando", label: "Processando" },
                      { value: "erro", label: "Erro" },
                    ]}
                    title=""
                    selectedValue={value}
                    onSelect={onChange}
                    disabled={desabilitarStatus}
                  />
                )}
              />
            </Form.Group>
          </InputContainer>
          <InputContainer>
            <Form.Group>
              <ButtonsContainer>
                <Button type="submit" disabled={desabilitarBuscar}>
                  Buscar
                </Button>
              </ButtonsContainer>
            </Form.Group>
          </InputContainer>
        </Row>
      </Form>
    </FormContainer>
  );
}
