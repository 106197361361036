import styled from "styled-components";
import 'react-datepicker/dist/react-datepicker.css';
import { PrimaryButton } from "components/Button/style";
import { Col } from "react-bootstrap";

export const Container = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonHeaderContainer = styled.span`
  width: 700px;
  background-color: red;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.neutral.neutral90};
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
`;

export const FormContainer = styled.div`
  margin-top: 2rem;

  select {
    border-radius: 8px;
    height: 38px;
  }

  .row {
    height: 125px;
  }
`;

export const InputContainer = styled(Col)`
  width: 20%;
  display: grid;
`;

export const DatePickerContainer = styled.div`
  display: grid;
  gap: 0.3rem;
  width: 100%;
`;

export const Button = styled(PrimaryButton)`
  width: 100px;
`;

export const ButtonsContainer = styled.div`
  position: relative;
  padding-left: 1.5rem;
  padding-top: 1.7rem;
  display: flex;
  justify-content: space-between;
`;