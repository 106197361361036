import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import { IData, IHttpError } from "_services/api/interface/HttpResponse";
import { IPagamentoTaxa } from "../Interfaces/IPagamentoTaxaLote";

export function usePagamentoLote() {
  const api = AxiosRequest.makeApi();

  const realizarPagamentoTaxa = async ({
    id_processo,
    cnpj_destinatario,
    nome_destinatario,
    municipio_destinatario,
  }: IPagamentoTaxa) => {
    try {
      const { status, data } = await api.post(
        `/pagamento/processo/${id_processo}/taxa/primeiro-registro`,
        { cnpj_destinatario, nome_destinatario, municipio_destinatario }
      );
      const response = new HttpResponse(false, status, data);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      return response;
    }
  };

  return {
    realizarPagamentoTaxa,
  };
}
