import { yupResolver } from "@hookform/resolvers/yup";
import { alertaErro } from "components/Alerta";
import { PrimaryButton } from "components/Button/style";
import { DropdownSelect } from "components/DropdownSelect";
import { LoadingScreen } from "components/LoadingScreen";
import { Required } from "components/Required";
import FileUpload from "components/UploadArquivos";
import { Page } from "containers/Page";
import { lerPlanilha } from "features/processo/utils/excel";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaDownload } from "react-icons/fa";
import { usePagamentoLote } from "../../Hooks/usePagamentoLote";
import {
  IFormPagamentoLote,
  IPagamentoTaxa,
  IPlanilhaPagamentoTaxa,
} from "../../Interfaces/IPagamentoTaxaLote";
import { pagamentoLoteSchema } from "../../Validate/validator";
import { ListagemResultadoPagamentoLote } from "./contaniers/ListagemResultadoPagamentoLote";
import { ButtonsContainer, Container } from "./style";

const municipios = [{ label: "São Paulo", value: "São Paulo" }];

function PagamentoLote() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dados, setDados] = useState<IPagamentoTaxa[]>([]);
  const [municipio, setMunicipio] = useState<string>(municipios[0].value);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(pagamentoLoteSchema),
    defaultValues: { municipio: municipios[0].value },
  });

  const { realizarPagamentoTaxa } = usePagamentoLote();

  function handleBaixarModelo() {
    const link = document.createElement("a");
    link.download = "Modelo_pagamento_lote_taxas_SP.xlsx";
    link.href = "/app/files/Modelo_pagamento_lote_taxas_SP.xlsx";
    link.click();
  }

  async function onSubmit({ arquivo, municipio }: IFormPagamentoLote) {
    setLoading(true);
    setDados([]);

    const dadosPlanailha = await lerPlanilha<IPlanilhaPagamentoTaxa>(arquivo);

    if (!dadosPlanailha.length) {
      alertaErro("Planilha vazia");
    }
    let count = 0;
    for (const linhaPlanailha of dadosPlanailha) {
      let cnpj_destinatario = linhaPlanailha["CNPJ_CPF_Destinatario"]?.replace(
        /[^0-9]/g,
        ""
      );

      const id_processo = Number(linhaPlanailha["ID_Processo"]);

      const dadosProcesso: IPagamentoTaxa = {
        id_processo,
        cnpj_destinatario,
        nome_destinatario: linhaPlanailha["Nome_Destinatario"],
        municipio_destinatario: municipio,
      };

      console.log("dadosProcesso", dadosProcesso);

      count++;
      const resultado = await realizarPagamentoTaxa(dadosProcesso);
      console.log("resultado", resultado);

      if (resultado.hasErro) {
        dadosProcesso.erros = resultado.data.message;
        dadosProcesso.lancou_custo = false;
        dadosProcesso.efetou_pagamento = false;
      }
      dadosProcesso.linkIdProcessoCriptografado =
        resultado.data.linkIdProcessoCriptografado;
      dadosProcesso.efetou_pagamento = resultado.data.pagamentoPrimeiroRegistro;
      dadosProcesso.lancou_custo = resultado.data.lancarCusto;
      if (resultado.data.lancarCustoMensagemErro) {
        dadosProcesso.erros += ` | ${resultado.data.lancarCustoMensagemErro}`;
      }
      setDados((prevDados) => [...prevDados, dadosProcesso]);
    }
    setLoading(false);
  }

  return (
    <Page title="Pagamento taxa SP em lote">
      <LoadingScreen mostrar={loading} />
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg="2">
              <Form.Group>
                <Controller
                  control={control}
                  name="municipio"
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      searchable
                      onSelect={(value) => {
                        if (value === null) {
                          reset();
                        }
                        setMunicipio(value);
                        onChange(value);
                      }}
                      options={municipios}
                      selectedValue={municipio}
                      title="Municipio"
                      mensagemErro={
                        isSubmitted ? errors.municipio?.message : null
                      }
                    />
                  )}
                />
              </Form.Group>
            </Col>

            <Col lg="3">
              <Form.Group>
                <Form.Label>
                  Arquivo (PDF autorização)
                  <Required />
                </Form.Label>

                <Controller
                  name="arquivo"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FileUpload
                      onFileUpload={(file) => onChange(file)}
                      mensagemErro={
                        isSubmitted ? (errors.arquivo?.message as any) : null
                      }
                      comMaxWidth={false}
                      gapContainer="0"
                      id="baixaManualInput"
                      inputAccept=".xlsx, xls"
                    />
                  )}
                />
              </Form.Group>
            </Col>

            <Col lg="2">
              <ButtonsContainer>
                <PrimaryButton buttonWidth="none" type="submit">
                  Consultar
                </PrimaryButton>
                <PrimaryButton
                  buttonWidth="none"
                  type="button"
                  onClick={handleBaixarModelo}
                  variante="primary"
                  outline
                >
                  Modelo <FaDownload />
                </PrimaryButton>
              </ButtonsContainer>
            </Col>
          </Row>
        </form>

        <div className="mt-4 bg-white p-3">
          <ListagemResultadoPagamentoLote dados={dados} />
        </div>
      </Container>
    </Page>
  );
}

export { PagamentoLote };
