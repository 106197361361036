import styled from "styled-components";
import temaPadrao from "_config/temas/estilo/base";

export const ToolsActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface IContainerTable {
  possuiTooltip?: boolean;
}

export const ContainerTable = styled.div<IContainerTable>`
  width: 100%;
  button {
    svg {
      color: #000000;
      width: 2rem;
      height: 2rem;
    }
  }

  .rdt_TableCol {
    padding: 0 8px;
    margin: 0 6px;
    
    div {
      display: flex;
      align-items: center;
      justify-content: start;
      font-size: 15px;
    }
  }

  .rdt_TableCell {
    padding: 0 8px;
    margin: 0 6px;
    justify-content: start;
    ${({ possuiTooltip }) => possuiTooltip ? "" :
    `
    div {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      scrollbar-width: none;
      width: 100%;

      &:hover {
        text-overflow: clip;
        white-space: normal;
        width: 100%;
      }
    }
    `
  }
}

  .rdt_TableRow {
    height: 100%;
  }
`;

export const NoDataComponentHeaderContainer = styled.table`
  color: ${temaPadrao.colors.dark};
  font-size: 12px;
  min-width: 0;
  margin-bottom: 8px;
  width: 100%;
  table-layout: auto;

  thead {
    background: ${temaPadrao.colors.neutral.neutral05};
  }

  th {
    padding: 0;
    height: 51px;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

export const paginationComponentOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: false,
  noRowsPerPage: false,
  selectAllRowsItemText: "Todos",
};

export const paginationRowsPerPageOptions = [100, 150, 200, 300]

export const customStyle = {
  headCells: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start !important",
      fontSize: "1rem",
      fontWeight: "bold",
      padding: "16px"
    },
  },
  headRow: {
    style: {
      background: temaPadrao.colors.neutral.neutral10,
      color: temaPadrao.colors.dark,
      minWidth: "0",
      paddingLeft: "14px",
    },
  },
  pagination: {
    style: {
      justifyContent: "center",
      pageButtonsStyle: {},
    },
  },
  cells: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "16px",
    },
  },
  rows: {
    style: {
      display: "flex",
      height: '52px',
      margin: '0',
      alignItems: "center",
      justifyContent: "flex-start",
      "&:not(:last-of-type)": {
        borderBottomWidth: "0px",
      },
      paddingLeft: "14px",
    },
    stripedStyle: {
      background: temaPadrao.colors.neutral.neutral05,
    },
    selectedHighlightStyle:{
      background:`${temaPadrao.colors.project.bgColor} !important`
    }
  },
};
