import { InfoTooltip } from "components/InfoTooltip";
import { toastErro, toastSucesso } from "components/Toast";
import { Tooltip } from "components/Tooltip";
import { CSSProperties, useState } from "react";
import { FaCopy } from "react-icons/fa";

interface IIconeCopiar {
  texto: string;
  mensagem: string;
  widthCell?: number;
  maxWidthTexto?: number;
  tooltipBottom?: number;
  tooltipWidth?: number;
  tooltipRight?: number;
}

export function IconeCopiar({
  texto,
  mensagem,
  widthCell = 220,
  maxWidthTexto = 220,
  tooltipBottom = -1,
  tooltipWidth = 380,
  tooltipRight = -140,
}: IIconeCopiar) {
  const styleHovered: CSSProperties = {
    width: `${widthCell}px`,
    marginRight: "5px",
    position: "relative",
  };

  const styleNormal: CSSProperties = {
    ...styleHovered,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  const onClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(texto)
        .then(() => {
          toastSucesso("Copiado para área de transferência!");
        })
        .catch((error) => {
          toastErro("Falha ao copiar");
        });
      return;
    }
    toastErro(
      "Funcionalidade indisponível devido a configurações do navegador"
    );
  };

  const [style, setStyle] = useState<CSSProperties>(styleNormal);

  return (
    <span
      style={{
        display: "inline-flex",
        alignItems: "center",
        maxWidth: `${maxWidthTexto}px`,
      }}
    >
      <div
        style={style}
        onMouseEnter={() => setStyle(styleHovered)}
        onMouseLeave={() => setStyle(styleNormal)}
      >
        {texto}
      </div>
      <div>
        <Tooltip
          positionRelative={false}
          right={tooltipRight}
          bottom={tooltipBottom}
          width={tooltipWidth}
          trigger={<FaCopy onClick={onClick} style={{ cursor: "pointer" }} />}
        >
          <InfoTooltip mensagem={mensagem} transformeX={0} />
        </Tooltip>
      </div>
    </span>
  );
}
