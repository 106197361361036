import { ModalCustom } from "components/ModalCustom";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Required } from "components/Required";
import { Controller, useForm } from "react-hook-form";
import temaPadrao from "_config/temas/estilo/base";
import { PrimaryButton } from "components/Button/style";
import { yupResolver } from "@hookform/resolvers/yup";
import { alterarRenavam } from "../../validate/validator";
import { ContainerForm } from "./style";
import { CustomInput } from "components/CustomInput";
import { useMaskInput } from "hooks/useMaskInput";

interface Props {
  show: boolean;
  handleClose: () => void;
  onSubmitUnitario: () => void;
  setRenavamEscolhido: (renavam: null | any) => void;
  renavamEscolhido: string | null;
}

export function ModalAlterarRenavam({
  show,
  handleClose,
  onSubmitUnitario,
  setRenavamEscolhido,
  renavamEscolhido,
}: Props) {
  const { mInputRenavam } = useMaskInput();
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(alterarRenavam),
  });

  useEffect(() => {
    reset({
      renavam: undefined,
    });
  }, [show]);

  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      title="Alterar Renavam"
      centered
    >
      <form
        onSubmit={handleSubmit(() => {
          onSubmitUnitario();
        })}
      >
        <ContainerForm>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label
                  style={{ color: temaPadrao.colors.neutral.neutral70 }}
                >
                  Renavam <Required />
                </Form.Label>
                <Controller
                  name="renavam"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      mensagemErro={""}
                      type="text"
                      placeholder="Informe o Renavam"
                      style={{ height: "2.2rem" }}
                      value={value}
                      onChange={(value) => {
                        mInputRenavam(value);
                        onChange(value);
                        if (!value) {
                          setRenavamEscolhido(null);
                          return;
                        }

                        setRenavamEscolhido(value.target.value);
                      }}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        </ContainerForm>
        <div className="d-flex justify-content-between gap-4 mt-4">
          <PrimaryButton
            style={{ width: "14rem" }}
            outline
            variante="primary"
            onClick={handleClose}
          >
            Cancelar
          </PrimaryButton>
          <PrimaryButton
            style={{ width: "14rem" }}
            type="submit"
            disabled={!renavamEscolhido}
          >
            Salvar
          </PrimaryButton>
        </div>
      </form>
    </ModalCustom>
  );
}
