import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const filtrosPagarIpvaLote = yup.object().shape({
  empresa: yup.number().optional().nullable(),
  divisao: yup.number().optional().nullable(),
  dataVencimento: yup.date().optional().nullable(),
  dataCadastroProcesso: yup.date().optional().nullable(),
  statusPagamento: yup.number().optional().nullable(),
});

export const pagamentoLoteSchema = yup.object().shape({
  municipio: yup.string().required("Municipio é obrigatório."),
  arquivo: yup
    .mixed()
    .test("file-selected", "Selecione um arquivo", (value) => {
      return value instanceof File && value.size > 0;
    })
    .required("Selecione um arquivo")
    .nonNullable(),
});
