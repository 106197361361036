import styled from "styled-components";

export const IconeContainer = styled.div`
  padding: 0.75rem 1rem;
  background-color: #FFEAEE;
  border-radius: 0.5rem;
  svg {
    color: #E41324;
  }
`;

export const TextContainer = styled.div`
  text-align: center;
`;

export const InfoContainer = styled.div`
  display: grid;
  place-items: center;
  gap: 1rem;
  padding: 1rem;
`;
