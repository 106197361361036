import { Erro } from "../StatusIcones/erro";
import { Finalizado } from "../StatusIcones/finalizado";
import { Processando } from "../StatusIcones/processando";
import { EtapaGerenciarEmplacamentosEnum } from "../../enums/EtapaGerenciarEmplacamentos.enum";
import { StatusEtapaGerenciarEmplacamentosEnum } from "../../enums/StatusEtapaGerenciarEmplacamentos.enum";
import { HttpResponse } from "_services/api/HttpResponse";
import { IListarEmplacamentosPR } from "../../Interfaces/IListarEmplacamentosPR";
import { IconeX } from "../StatusIcones/iconeX";

interface HandleStatusFinalizarProcessoProps {
  etapa: EtapaGerenciarEmplacamentosEnum;
  status: StatusEtapaGerenciarEmplacamentosEnum;
  finalizarProcesso: (id: number) => Promise<HttpResponse>;
  idProcesso: number;
  dados: IListarEmplacamentosPR[];
  setDados: (dados: IListarEmplacamentosPR[]) => void;
  setTooltipLeft: (left: number) => void;
  setTooltipTop: (top: number) => void;
  setTooltipConteudo: (conteudo: string | JSX.Element) => void;
  setTooltipWidth: (width: number) => void;
  setShowTooltip: (show: boolean) => void;
  abrirModalErroEtapaSemManual: (
    id: number,
    mensagemErro: string,
    etapaComErro: EtapaGerenciarEmplacamentosEnum
  ) => void;
  mensagemErro: string;
}
export function HandleStatusFinalizarProcesso({
  etapa,
  status,
  finalizarProcesso,
  idProcesso,
  dados,
  setDados,
  setTooltipLeft,
  setTooltipTop,
  setTooltipConteudo,
  setTooltipWidth,
  setShowTooltip,
  abrirModalErroEtapaSemManual,
  mensagemErro,
}: HandleStatusFinalizarProcessoProps) {
  const handleStatus = (
    status: StatusEtapaGerenciarEmplacamentosEnum,
    mensagem?: string
  ) => {
    dados.forEach((processo) => {
      if (processo.id == idProcesso) {
        processo.statusEtapa = status;
        processo.etapa = EtapaGerenciarEmplacamentosEnum.FINALIZAR_PROCESSO;

        if (mensagem) {
          processo.observacao = mensagem;
        }
      }
    });
    setDados(dados);
  };

  const onClick = async () => {
    handleStatus(StatusEtapaGerenciarEmplacamentosEnum.PROCESSANDO);
    setDados([...dados]);

    const { hasErro, data } = await finalizarProcesso(idProcesso);

    setShowTooltip(false);
    setTooltipLeft(0);
    setTooltipTop(0);
    setTooltipConteudo("");

    if (hasErro) {
      handleStatus(
        StatusEtapaGerenciarEmplacamentosEnum.ERRO,
        data?.message ?? null
      );
      return;
    }

    handleStatus(StatusEtapaGerenciarEmplacamentosEnum.FINALIZADO);
  };

  if (
    etapa == EtapaGerenciarEmplacamentosEnum.FINALIZAR_PROCESSO &&
    status == StatusEtapaGerenciarEmplacamentosEnum.FINALIZADO
  ) {
    return <Finalizado />;
  }

  if (
    etapa == EtapaGerenciarEmplacamentosEnum.FINALIZAR_PROCESSO &&
    status == StatusEtapaGerenciarEmplacamentosEnum.ERRO
  ) {
    return (
      <Erro
        onClick={() =>
          abrirModalErroEtapaSemManual(
            idProcesso,
            mensagemErro,
            EtapaGerenciarEmplacamentosEnum.FINALIZAR_PROCESSO
          )
        }
      />
    );
  }

  if (
    etapa == EtapaGerenciarEmplacamentosEnum.FINALIZAR_PROCESSO &&
    status == StatusEtapaGerenciarEmplacamentosEnum.PROCESSANDO
  ) {
    return (
      <Processando
        setTooltipLeft={setTooltipLeft}
        setTooltipTop={setTooltipTop}
        setTooltipConteudo={setTooltipConteudo}
        setTooltipWidth={setTooltipWidth}
        setShowTooltip={setShowTooltip}
      />
    );
  }

  return <IconeX acao={onClick} />;
}
