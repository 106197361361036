import {
  FaArrowAltCircleRight,
  FaCar,
  FaDollarSign,
  FaDolly,
  FaEdit,
  FaFileAlt,
  FaFileContract,
  FaFileExcel,
  FaFilePdf,
  FaTimes,
  FaUndo,
} from "react-icons/fa";
import { Container, Opcao, OpcaoTitulo, TextoOpcao, Titulo } from "./style";
import { LoadingCirculo } from "components/LoadingCirculo";
import { TextoClaro } from "../ModalInfo/style";

interface Props {
  aberto: boolean;
  onClickSolicitarExcel: () => void;
  onClickRelatorioExcel: () => void;
  onClickRelatorioZip: () => void;
  loading: boolean;
}

export function OpcoesSolicitarPlaca({
  aberto,
  onClickSolicitarExcel,
  onClickRelatorioExcel,
  onClickRelatorioZip,
  loading,
}: Props) {
  return (
    <Container aberto={aberto && !loading}>
      <div>
        <OpcaoTitulo>Solicitar</OpcaoTitulo>
        <Opcao>
          <TextoOpcao onClick={onClickSolicitarExcel}>
            <FaFileExcel style={{ marginRight: 5, width: 14 }} /> Excel{" "}
            <TextoClaro>(Relação de placas)</TextoClaro>
          </TextoOpcao>
        </Opcao>
        <div style={{ padding: "0 12px" }}>
          <hr />
        </div>
        <OpcaoTitulo>Relatório</OpcaoTitulo>
        <Opcao>
          <TextoOpcao onClick={onClickRelatorioExcel}>
            <FaFileExcel style={{ marginRight: 5, width: 14 }} /> Excel{" "}
            <TextoClaro>(Relação de placas)</TextoClaro>
          </TextoOpcao>
        </Opcao>
        <Opcao>
          <TextoOpcao onClick={onClickRelatorioZip}>
            <FaFilePdf style={{ marginRight: 5, width: 14 }} /> Zip{" "}
            <TextoClaro>(CRLV em pdf)</TextoClaro>
          </TextoOpcao>
        </Opcao>
      </div>
    </Container>
  );
}
