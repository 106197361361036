import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { converterDataBr, converterDataHoraBr } from "_utils/Data";
import { Button, Container, DivAcoes, Header, TextoHeader } from "./style";
import { IListarEmplacamentosPR } from "../../Interfaces/IListarEmplacamentosPR";
import { FaAngleDown, FaInfoCircle } from "react-icons/fa";
import { formatarDinheiro } from "_utils/Money";
import { IconeCopiar } from "components/IconeCopiar";
import { IconeEditar } from "components/IconeEditar";
import { ModalInfoEmplacamento } from "../ModalInfo";
import { useState } from "react";
import { TooltipV2 } from "components/TooltipV2";
import { OpcoesAcoes } from "../OpcoesAcoes";
import { OpcoesSolicitarPlaca } from "../OpcoesSolicitarPlaca";
import { HandleStatusEscolherPlaca } from "../HandleStatus/handleStatusEscolherPlaca";
import { HandleStatusGerarProcesso } from "../HandleStatus/handleStatusGerarProcesso";
import { HandleStatusPagarTaxa } from "../HandleStatus/handleStatusPagarTaxa";
import { HandleStatusApropriarProcesso } from "../HandleStatus/handleStatusApropriarProcesso";
import { HandleStatusPagarIpva } from "../HandleStatus/handleStatusPagarIpva";
import { HttpResponse } from "_services/api/HttpResponse";
import { HandleStatusEmitirLicenciamento } from "../HandleStatus/handleStatusEmitirLicenciamento";
import { HandleStatusSolicitarPlaca } from "../HandleStatus/handleStatusSolicitarPlaca";
import { HandleStatusFinalizarProcesso } from "../HandleStatus/handleStatusFinalizarProcesso";
import { EtapaGerenciarEmplacamentosEnum } from "../../enums/EtapaGerenciarEmplacamentos.enum";
import { TooltipConteudo } from "../TooltipConteudo";

interface IListarGerenciarEmplacamentosProps {
  dados: IListarEmplacamentosPR[];
  setDados: (dados: IListarEmplacamentosPR[]) => void;
  handleLimitChange: (e: number) => void;
  handlePageChange: (e: number) => void;
  totalRows: number;
  gerarProcesso: (id: number) => Promise<HttpResponse>;
  escolherPlaca: (id: number, sigla?: string) => Promise<HttpResponse>;
  pagarIpva: (id: number) => Promise<HttpResponse>;
  pagarTaxa: (id: number) => Promise<HttpResponse>;
  apropriarProcesso: (id: number) => Promise<HttpResponse>;
  emitirLicenciamento: (id: number) => Promise<HttpResponse>;
  solicitarPlaca: (id: number) => Promise<HttpResponse>;
  finalizarProcesso: (id: number) => Promise<HttpResponse>;
  gerarExcelPedidoPlacas: (ids: number[]) => any;
  setRowsSelected: (ids: number[]) => void;
  setAcaoEmLote: (acao: EtapaGerenciarEmplacamentosEnum) => void;
  abrirModalAlterarAgenteFinanceiro: (tipoSubmit: "LOTE" | "UNITARIO") => void;
  abrirModalAlterarDespachante: (tipoSubmit: "LOTE" | "UNITARIO") => void;
  handleReprocessar: () => void;
  abrirModalAlterarRenavam: () => void;
  abrirModalAlterarPlaca: () => void;
  setIdProcessoEditado: (id: number) => void;
  showOpcoesAcoes: boolean;
  setShowOpcoesAcoes: (show: boolean) => void;
  showOpcoesSolicitarPlaca: boolean;
  setShowOpcoesSolicitarPlaca: (show: boolean) => void;
  setIdVeiculoEscolhido: (id: number) => void;
  setRenavamEscolhido: (id: string) => void;
  setPlacaEscolhida: (id: string) => void;
  loadingOpcoes: boolean;
  loadingLista: boolean;
  onClickSolicitarExcel: () => void;
  onClickRelatorioExcel: () => void;
  onClickRelatorioZip: () => void;
  disabledBotaoAcao: boolean;
  abrirModalErroGerarProcesso: (id: number, mensagemErro: string) => void;
  abrirModalErroEscolherPlaca: (id: number, mensagemErro: string) => void;
  abrirModalErroEmitirLicenciamento: (id: number, mensagemErro: string) => void;
  abrirModalErroEtapaSemManual: (
    id: number,
    mensagemErro: string,
    etapaComErro: EtapaGerenciarEmplacamentosEnum
  ) => void;
}

export function ListarGerenciarEmplacamentos({
  dados,
  setDados,
  handleLimitChange,
  handlePageChange,
  totalRows,
  gerarProcesso,
  escolherPlaca,
  pagarIpva,
  pagarTaxa,
  apropriarProcesso,
  emitirLicenciamento,
  solicitarPlaca,
  finalizarProcesso,
  gerarExcelPedidoPlacas,
  setRowsSelected,
  setAcaoEmLote,
  abrirModalAlterarAgenteFinanceiro,
  abrirModalAlterarDespachante,
  setIdProcessoEditado,
  showOpcoesAcoes,
  setShowOpcoesAcoes,
  showOpcoesSolicitarPlaca,
  setShowOpcoesSolicitarPlaca,
  abrirModalAlterarRenavam,
  setIdVeiculoEscolhido,
  setRenavamEscolhido,
  setPlacaEscolhida,
  abrirModalAlterarPlaca,
  loadingOpcoes,
  loadingLista,
  onClickSolicitarExcel,
  onClickRelatorioExcel,
  onClickRelatorioZip,
  handleReprocessar,
  disabledBotaoAcao,
  abrirModalErroGerarProcesso,
  abrirModalErroEscolherPlaca,
  abrirModalErroEmitirLicenciamento,
  abrirModalErroEtapaSemManual,
}: IListarGerenciarEmplacamentosProps) {
  const [showModalInfo, setShowModalInfo] = useState<boolean>(false);

  const [tooltipLeft, setTooltipLeft] = useState<number>(0);
  const [tooltipTop, setTooltipTop] = useState<number>(0);
  const infoEmplacamentoDefault = { categoria: "", usuarioSolicitante: "" };
  const [infoEmplacamento, setInfoEmplacamento] = useState<{
    categoria: string;
    usuarioSolicitante: string;
  }>(infoEmplacamentoDefault);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipConteudo, setTooltipConteudo] = useState<string | JSX.Element>(
    ""
  );
  const [tooltipWidth, setTooltipWidth] = useState<number>(500);

  const habilitar = (escolhaPlaca: string | number) => {
    if (
      escolhaPlaca &&
      `${escolhaPlaca}`.length > 0 &&
      !["S/ PLACA", "S/PLACA", "s/ placa"].includes(`${escolhaPlaca}`)
    ) {
      return true;
    }

    if (escolhaPlaca === 0 || escolhaPlaca === "0") {
      return true;
    }

    return false;
  };

  const columns: IColum<IListarEmplacamentosPR>[] = [
    {
      name: <span>ID</span>,
      selector: ({ id }: IListarEmplacamentosPR) => id,
    },
    {
      name: <span>Placa</span>,
      selector: ({ placa, idVeiculo, renavam }: IListarEmplacamentosPR) => (
        <IconeEditar
          texto={placa}
          maxWidthTexto={110}
          onClick={() => {
            setIdVeiculoEscolhido(idVeiculo);
            setRenavamEscolhido(renavam);
            abrirModalAlterarPlaca();
          }}
        />
      ),
      width: "120px",
    },
    {
      name: <span>Renavam</span>,
      selector: ({ renavam, idVeiculo, placa }: IListarEmplacamentosPR) => (
        <IconeEditar
          texto={renavam}
          maxWidthTexto={110}
          onClick={() => {
            setIdVeiculoEscolhido(idVeiculo);
            setPlacaEscolhida(placa);
            abrirModalAlterarRenavam();
          }}
        />
      ),
      width: "120px",
    },

    {
      name: <span>Chassi</span>,
      selector: ({ chassi }: IListarEmplacamentosPR) =>
        chassi ? (
          <IconeCopiar
            texto={chassi}
            mensagem="Copiar n° chassi"
            widthCell={600}
            maxWidthTexto={140}
            tooltipRight={-150}
          />
        ) : (
          ""
        ),
      width: "147px",
    },
    {
      name: <span>Dt. Cadastro Processo</span>,
      selector: ({ dataCadastroProcesso }: IListarEmplacamentosPR) =>
        dataCadastroProcesso ? converterDataHoraBr(dataCadastroProcesso) : "--",
      width: "150px",
    },
    {
      name: <span>Escolha de Placa</span>,
      selector: ({ escolhaPlaca }: IListarEmplacamentosPR) => escolhaPlaca,
      width: "150px",
    },
    {
      name: <span>Número Processo</span>,
      selector: ({ numeroProcesso }: IListarEmplacamentosPR) => numeroProcesso,
      width: "150px",
    },
    {
      name: <span>Dt / Hr Processo</span>,
      selector: ({ dataHoraProcesso }: IListarEmplacamentosPR) =>
        dataHoraProcesso ? converterDataHoraBr(dataHoraProcesso) : "--",
      width: "150px",
    },
    {
      name: <span>Despachante</span>,
      selector: ({ despachante, id }: IListarEmplacamentosPR) => (
        <IconeEditar
          texto={despachante}
          onClick={() => {
            setIdProcessoEditado(id);
            abrirModalAlterarDespachante("UNITARIO");
          }}
        />
      ),
      width: "150px",
    },
    {
      name: <span>Agente Financeiro</span>,
      selector: ({ agenteFinanceiro, id }: IListarEmplacamentosPR) => (
        <IconeEditar
          texto={agenteFinanceiro}
          onClick={() => {
            setIdProcessoEditado(id);
            abrirModalAlterarAgenteFinanceiro("UNITARIO");
          }}
        />
      ),
      width: "150px",
    },
    {
      name: <span>Código de Barras</span>,
      selector: ({ codigoBarras }: IListarEmplacamentosPR) => {
        if (codigoBarras) {
          return (
            <IconeCopiar
              texto={codigoBarras}
              mensagem="Copiar código de barras"
              widthCell={600}
              maxWidthTexto={200}
              tooltipRight={-150}
            />
          );
        }

        return "";
      },
      width: "190px",
    },
    {
      name: <span>Valor / Dt Vencimento</span>,
      selector: ({ valor, dataVencimento }: IListarEmplacamentosPR) => (
        <span>
          {`${valor ? formatarDinheiro(Number(valor)) : ""}`}
          {valor && dataVencimento ? <br /> : ""}
          {`${dataVencimento ? converterDataBr(dataVencimento) : ""}`}
        </span>
      ),
      width: "120px",
    },
    {
      name: <span>ESC PLA</span>,
      selector: ({
        etapa,
        statusEtapa,
        escolhaPlaca,
        id,
        observacao,
      }: IListarEmplacamentosPR) => {
        return (
          <HandleStatusEscolherPlaca
            habilitar={habilitar(escolhaPlaca)}
            etapa={etapa}
            status={statusEtapa}
            escolherPlaca={escolherPlaca}
            idProcesso={id}
            dados={dados}
            setDados={setDados}
            setTooltipLeft={setTooltipLeft}
            setTooltipTop={setTooltipTop}
            setTooltipConteudo={setTooltipConteudo}
            setTooltipWidth={setTooltipWidth}
            setShowTooltip={setShowTooltip}
            abrirModalErroEscolherPlaca={abrirModalErroEscolherPlaca}
            mensagemErro={observacao}
          />
        );
      },
      width: "60px",
    },
    {
      name: <span>GP</span>,
      selector: ({
        etapa,
        statusEtapa,
        id,
        observacao,
        escolhaPlaca,
        codigoBarras,
      }: IListarEmplacamentosPR) => {
        return (
          <HandleStatusGerarProcesso
            habilitarEscolhaPlaca={habilitar(escolhaPlaca)}
            etapa={etapa}
            status={statusEtapa}
            gerarProcesso={gerarProcesso}
            idProcesso={id}
            dados={dados}
            setDados={setDados}
            setTooltipLeft={setTooltipLeft}
            setTooltipTop={setTooltipTop}
            setTooltipConteudo={setTooltipConteudo}
            setTooltipWidth={setTooltipWidth}
            setShowTooltip={setShowTooltip}
            abrirModalErroGerarProcesso={abrirModalErroGerarProcesso}
            mensagemErro={observacao}
            codigoDeBarras={codigoBarras}
          />
        );
      },
      width: "60px",
    },
    {
      name: <span>TAXA</span>,
      selector: ({
        etapa,
        statusEtapa,
        id,
        observacao,
        codigoBarras,
      }: IListarEmplacamentosPR) => {
        return (
          <HandleStatusPagarTaxa
            etapa={etapa}
            status={statusEtapa}
            pagarTaxa={pagarTaxa}
            idProcesso={id}
            dados={dados}
            setDados={setDados}
            setTooltipLeft={setTooltipLeft}
            setTooltipTop={setTooltipTop}
            setTooltipConteudo={setTooltipConteudo}
            setTooltipWidth={setTooltipWidth}
            setShowTooltip={setShowTooltip}
            abrirModalErroEtapaSemManual={abrirModalErroEtapaSemManual}
            mensagemErro={observacao}
            codigoDeBarras={codigoBarras}
          />
        );
      },
      width: "60px",
    },
    {
      name: <span>AP</span>,
      selector: ({
        etapa,
        statusEtapa,
        id,
        observacao,
        codigoBarras,
      }: IListarEmplacamentosPR) => {
        return (
          <HandleStatusApropriarProcesso
            etapa={etapa}
            status={statusEtapa}
            apropriarProcesso={apropriarProcesso}
            idProcesso={id}
            dados={dados}
            setDados={setDados}
            setTooltipLeft={setTooltipLeft}
            setTooltipTop={setTooltipTop}
            setTooltipConteudo={setTooltipConteudo}
            setTooltipWidth={setTooltipWidth}
            setShowTooltip={setShowTooltip}
            abrirModalErroEtapaSemManual={abrirModalErroEtapaSemManual}
            mensagemErro={observacao}
            codigoDeBarras={codigoBarras}
          />
        );
      },
      width: "60px",
    },
    {
      name: <span>IPVA</span>,
      selector: ({
        etapa,
        statusEtapa,
        id,
        observacao,
        codigoBarras,
      }: IListarEmplacamentosPR) => {
        return (
          <HandleStatusPagarIpva
            etapa={etapa}
            status={statusEtapa}
            pagarIpva={pagarIpva}
            idProcesso={id}
            dados={dados}
            setDados={setDados}
            setTooltipLeft={setTooltipLeft}
            setTooltipTop={setTooltipTop}
            setTooltipConteudo={setTooltipConteudo}
            setTooltipWidth={setTooltipWidth}
            setShowTooltip={setShowTooltip}
            abrirModalErroEtapaSemManual={abrirModalErroEtapaSemManual}
            mensagemErro={observacao}
            codigoDeBarras={codigoBarras}
          />
        );
      },
      width: "60px",
    },
    {
      name: <span>LIC</span>,
      selector: ({
        etapa,
        statusEtapa,
        id,
        observacao,
        codigoBarras,
      }: IListarEmplacamentosPR) => {
        return (
          <HandleStatusEmitirLicenciamento
            etapa={etapa}
            status={statusEtapa}
            emitirLicenciamento={emitirLicenciamento}
            idProcesso={id}
            dados={dados}
            setDados={setDados}
            setTooltipLeft={setTooltipLeft}
            setTooltipTop={setTooltipTop}
            setTooltipConteudo={setTooltipConteudo}
            setTooltipWidth={setTooltipWidth}
            setShowTooltip={setShowTooltip}
            abrirModalErroEmitirLicenciamento={
              abrirModalErroEmitirLicenciamento
            }
            mensagemErro={observacao}
            codigoDeBarras={codigoBarras}
          />
        );
      },
      width: "60px",
    },
    {
      name: <span>PLA</span>,
      selector: ({
        etapa,
        statusEtapa,
        id,
        observacao,
        codigoBarras,
      }: IListarEmplacamentosPR) => {
        return (
          <HandleStatusSolicitarPlaca
            etapa={etapa}
            status={statusEtapa}
            solicitarPlaca={solicitarPlaca}
            idProcesso={id}
            dados={dados}
            setDados={setDados}
            gerarExcelPedidoPlacas={gerarExcelPedidoPlacas}
            setTooltipLeft={setTooltipLeft}
            setTooltipTop={setTooltipTop}
            setTooltipConteudo={setTooltipConteudo}
            setTooltipWidth={setTooltipWidth}
            setShowTooltip={setShowTooltip}
            abrirModalErroEtapaSemManual={abrirModalErroEtapaSemManual}
            mensagemErro={observacao}
            codigoDeBarras={codigoBarras}
          />
        );
      },
      width: "60px",
    },
    {
      name: <span>FIN</span>,
      selector: ({
        etapa,
        statusEtapa,
        id,
        observacao,
      }: IListarEmplacamentosPR) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            onMouseEnter={(
              event: React.MouseEvent<HTMLSpanElement, MouseEvent>
            ) => {
              const { left, top } = event.currentTarget.getBoundingClientRect();
              setTooltipLeft(left);
              setTooltipTop(top);
              setTooltipConteudo(
                <span>
                  Ao finalizar, as informações poderão ser consultadas somente
                  pela página de <strong>Processo</strong>
                </span>
              );
              setTooltipWidth(400);
              setShowTooltip(true);
            }}
            onMouseLeave={() => {
              setShowTooltip(false);
              setTooltipLeft(0);
              setTooltipTop(0);
              setTooltipConteudo("");
            }}
          >
            <HandleStatusFinalizarProcesso
              etapa={etapa}
              status={statusEtapa}
              finalizarProcesso={finalizarProcesso}
              idProcesso={id}
              dados={dados}
              setDados={setDados}
              setTooltipLeft={setTooltipLeft}
              setTooltipTop={setTooltipTop}
              setTooltipConteudo={setTooltipConteudo}
              setTooltipWidth={setTooltipWidth}
              setShowTooltip={setShowTooltip}
              abrirModalErroEtapaSemManual={abrirModalErroEtapaSemManual}
              mensagemErro={observacao}
            />
          </span>
        );
      },
      width: "60px",
    },
    {
      name: <span>INFO</span>,
      selector: ({ categoria, usuarioSolicitante }: IListarEmplacamentosPR) => (
        <FaInfoCircle
          style={{ cursor: "pointer" }}
          onMouseEnter={(event: React.MouseEvent<SVGElement, MouseEvent>) => {
            const { left, top } = event.currentTarget.getBoundingClientRect();
            setTooltipLeft(left);
            setTooltipTop(top);
            setInfoEmplacamento({ categoria, usuarioSolicitante });
            setShowModalInfo(true);
          }}
          onMouseLeave={() => {
            setShowModalInfo(false);
            setTooltipLeft(0);
            setTooltipTop(0);
            setInfoEmplacamento(infoEmplacamentoDefault);
          }}
        />
      ),
      width: "60px",
    },
  ];

  return (
    <Container>
      <Header>
        <TextoHeader>Listagem - Gerenciar Emplacamento</TextoHeader>
        <DivAcoes>
          <span
            onMouseEnter={(
              event: React.MouseEvent<HTMLSpanElement, MouseEvent>
            ) => {
              if (!disabledBotaoAcao) {
                return;
              }
              const { left, top } = event.currentTarget.getBoundingClientRect();
              setTooltipLeft(left);
              setTooltipTop(top);
              setTooltipConteudo(
                "Selecione itens na listagem para fazer o processamento em massa."
              );
              setTooltipWidth(260);
              setShowTooltip(true);
            }}
            onMouseLeave={() => {
              setShowTooltip(false);
              setTooltipLeft(0);
              setTooltipTop(0);
              setTooltipConteudo("");
            }}
          >
            <Button
              onClick={() => {
                if (showOpcoesAcoes) {
                  setShowOpcoesSolicitarPlaca(false);
                }
                setShowOpcoesAcoes(!showOpcoesAcoes);
              }}
              disabled={disabledBotaoAcao}
            >
              Ações <FaAngleDown />
            </Button>
          </span>

          <OpcoesAcoes
            aberto={showOpcoesAcoes}
            onClickSolicitarPlaca={() =>
              setShowOpcoesSolicitarPlaca(!showOpcoesSolicitarPlaca)
            }
            loading={loadingOpcoes}
            onClickEscolherPlaca={() => {
              setAcaoEmLote(EtapaGerenciarEmplacamentosEnum.ESCOLHER_PLACA);
              setShowOpcoesSolicitarPlaca(false);
              setShowOpcoesAcoes(false);
            }}
            onClickGerarProcesso={() => {
              setAcaoEmLote(EtapaGerenciarEmplacamentosEnum.GERAR_PROCESSO);
              setShowOpcoesSolicitarPlaca(false);
              setShowOpcoesAcoes(false);
            }}
            onClickPagarTaxa={() => {
              setAcaoEmLote(EtapaGerenciarEmplacamentosEnum.PAGAR_TAXA);
              setShowOpcoesSolicitarPlaca(false);
              setShowOpcoesAcoes(false);
            }}
            onClickApropriarProcesso={() => {
              setAcaoEmLote(EtapaGerenciarEmplacamentosEnum.APROPRIAR_PROCESSO);
              setShowOpcoesSolicitarPlaca(false);
              setShowOpcoesAcoes(false);
            }}
            onClickPagarIpva={() => {
              setAcaoEmLote(EtapaGerenciarEmplacamentosEnum.PAGAR_IPVA);
              setShowOpcoesSolicitarPlaca(false);
              setShowOpcoesAcoes(false);
            }}
            onClickEmitirLicenciamento={() => {
              setAcaoEmLote(
                EtapaGerenciarEmplacamentosEnum.EMITIR_LICENCIAMENTO
              );
              setShowOpcoesSolicitarPlaca(false);
              setShowOpcoesAcoes(false);
            }}
            onClickFinalizarProcesso={() => {
              setAcaoEmLote(EtapaGerenciarEmplacamentosEnum.FINALIZAR_PROCESSO);
              setShowOpcoesSolicitarPlaca(false);
              setShowOpcoesAcoes(false);
            }}
            onClickEditarAgenteFinanceiro={() =>
              abrirModalAlterarAgenteFinanceiro("LOTE")
            }
            onClickEditarDespachante={() =>
              abrirModalAlterarDespachante("LOTE")
            }
            onClickReprocessar={handleReprocessar}
          />
          <OpcoesSolicitarPlaca
            aberto={showOpcoesAcoes && showOpcoesSolicitarPlaca}
            onClickSolicitarExcel={onClickSolicitarExcel}
            onClickRelatorioExcel={onClickRelatorioExcel}
            onClickRelatorioZip={onClickRelatorioZip}
            loading={loadingOpcoes}
          />
        </DivAcoes>
      </Header>
      <hr />
      <TooltipV2
        children={<ModalInfoEmplacamento dados={infoEmplacamento} />}
        top={tooltipTop}
        left={tooltipLeft}
        width={500}
        show={showModalInfo}
      />
      <TooltipV2
        children={<TooltipConteudo mensagem={tooltipConteudo} />}
        top={tooltipTop}
        left={tooltipLeft}
        width={tooltipWidth}
        show={showTooltip}
      />
      <Table
        keyField="id"
        totalRows={totalRows}
        pagination={true}
        columns={columns}
        data={dados}
        paginationRowsPerPageOptions={[10, 20, 50]}
        loading={loadingLista}
        onChangeRowsPerPage={handleLimitChange}
        onChangePage={handlePageChange}
        noSubHeader
        possuiTooltip={false}
        selectableRows
        setRowsSelected={setRowsSelected}
      />
    </Container>
  );
}
