import { ModalCustom } from "components/ModalCustom";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Required } from "components/Required";
import { Controller, useForm } from "react-hook-form";
import temaPadrao from "_config/temas/estilo/base";
import { PrimaryButton } from "components/Button/style";
import { yupResolver } from "@hookform/resolvers/yup";
import { prosseguirManualmenteEmitirLicenciamento } from "../../validate/validator";
import { ContainerForm } from "./style";
import { CustomInput } from "components/CustomInput";
import FileUpload from "components/UploadArquivos";
import { CustomDatePicker } from "components/DatePicker";
import { IEmitirLicenciamentoManualmente } from "../../Interfaces/IEmitirLicenciamentoManualmente";

interface Props {
  show: boolean;
  handleClose: () => void;
  onSubmit: (dados: IEmitirLicenciamentoManualmente) => void;
}

export function ModalProsseguirManualmenteEmitirLicenciamento({
  show,
  handleClose,
  onSubmit,
}: Props) {
  const [limparArquivo, setLimparArquivo] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(prosseguirManualmenteEmitirLicenciamento),
  });

  useEffect(() => {
    reset({
      crlv: undefined,
    });
    setLimparArquivo(true);
  }, [show]);

  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      title="Prosseguir manualmente"
      centered
    >
      <form
        onSubmit={handleSubmit((dados: IEmitirLicenciamentoManualmente) => {
          onSubmit(dados);
        })}
      >
        <ContainerForm>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label
                  style={{ color: temaPadrao.colors.neutral.neutral70 }}
                >
                  CRLV
                </Form.Label>
                <Controller
                  name="crlv"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FileUpload
                      onFileUpload={(file) => {
                        onChange(file);
                      }}
                      mensagemErro={errors.crlv?.message}
                      limparArquivo={limparArquivo}
                      comMaxWidth={false}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        </ContainerForm>
        <div className="d-flex justify-content-between gap-4 mt-4">
          <PrimaryButton
            style={{ width: "14rem" }}
            outline
            variante="primary"
            onClick={handleClose}
          >
            Cancelar
          </PrimaryButton>
          <PrimaryButton style={{ width: "14rem" }} type="submit">
            Prosseguir
          </PrimaryButton>
        </div>
      </form>
    </ModalCustom>
  );
}
