import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const gerenciarEmplacamentoFiltroPR = yup.object().shape({
  empresa: yup.number().required(),
  divisao: yup.number().required(),
  etapa: yup.string().optional().nullable(),
  statusEtapa: yup.string().optional().nullable(),
});

export const alterarAgenteFinanceiro = yup.object().shape({
  agenteFinanceiro: yup.number().required(),
});

export const alterarDespachante = yup.object().shape({
  despachante: yup.number().required(),
});

export const alterarRenavam = yup.object().shape({
  renavam: yup.string().required(),
});

export const alterarPlaca = yup.object().shape({
  placa: yup.string().required(),
});

export const prosseguirManualmenteGerarProcesso = yup.object().shape({
  renavam: yup.string().required(),
  numeroProcesso: yup.string().required(),
  dataHoraProcesso: yup.string().required(),
  numeroGrd: yup.string().required(),
  capaRenavam: yup.mixed().nullable(),
});

export const prosseguirManualmenteEscolherPlaca = yup.object().shape({
  placa: yup.string().required(),
});

export const prosseguirManualmenteEmitirLicenciamento = yup.object().shape({
    crlv: yup.mixed().required(),
  });
