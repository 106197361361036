import {
  FaAngleRight,
  FaArrowAltCircleRight,
  FaCar,
  FaDollarSign,
  FaEdit,
  FaFileAlt,
  FaTimes,
  FaUndo,
} from "react-icons/fa";
import { Container, Opcao, TextoOpcao, Titulo } from "./style";
import { LoadingCirculo } from "components/LoadingCirculo";

interface Props {
  aberto: boolean;
  onClickSolicitarPlaca: () => void;
  loading: boolean;
  onClickEscolherPlaca: () => void;
  onClickGerarProcesso: () => void;
  onClickPagarTaxa: () => void;
  onClickApropriarProcesso: () => void;
  onClickPagarIpva: () => void;
  onClickEmitirLicenciamento: () => void;
  onClickFinalizarProcesso: () => void;
  onClickEditarAgenteFinanceiro: () => void;
  onClickEditarDespachante: () => void;
  onClickReprocessar: () => void;
}

export function OpcoesAcoes({
  aberto,
  onClickSolicitarPlaca,
  loading,
  onClickEscolherPlaca,
  onClickGerarProcesso,
  onClickPagarTaxa,
  onClickApropriarProcesso,
  onClickPagarIpva,
  onClickEmitirLicenciamento,
  onClickFinalizarProcesso,
  onClickEditarAgenteFinanceiro,
  onClickEditarDespachante,
  onClickReprocessar,
}: Props) {
  return (
    <Container aberto={aberto}>
      {loading ? (
        <LoadingCirculo mostrar={loading} />
      ) : (
        <div>
          <Opcao>
            <TextoOpcao onClick={onClickEscolherPlaca}>
              <FaCar style={{ marginRight: 8, width: 16 }} /> Escolher placa
            </TextoOpcao>
          </Opcao>
          <Opcao>
            <TextoOpcao onClick={onClickGerarProcesso}>
              <FaArrowAltCircleRight style={{ marginRight: 8, width: 16 }} />{" "}
              Gerar processo
            </TextoOpcao>
          </Opcao>
          <Opcao>
            <TextoOpcao onClick={onClickPagarTaxa}>
              <FaDollarSign style={{ marginRight: 8, width: 16 }} /> Pagar taxa
            </TextoOpcao>
          </Opcao>
          <Opcao>
            <TextoOpcao onClick={onClickApropriarProcesso}>
              <FaArrowAltCircleRight style={{ marginRight: 8, width: 16 }} />{" "}
              Apropriar processo
            </TextoOpcao>
          </Opcao>
          <Opcao>
            <TextoOpcao onClick={onClickPagarIpva}>
              <FaDollarSign style={{ marginRight: 8, width: 16 }} /> Pagar IPVA
            </TextoOpcao>
          </Opcao>
          <Opcao>
            <TextoOpcao onClick={onClickEmitirLicenciamento}>
              <FaFileAlt style={{ marginRight: 8, width: 16 }} /> Emitir
              licenciamento
            </TextoOpcao>
          </Opcao>
          <Opcao>
            <TextoOpcao onClick={onClickSolicitarPlaca}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <FaCar style={{ marginRight: 8, width: 16 }} /> Solicitar
                  placa
                </div>
                <div>
                  <FaAngleRight />
                </div>
              </div>
            </TextoOpcao>
          </Opcao>
          <Opcao>
            <TextoOpcao onClick={onClickFinalizarProcesso}>
              <FaTimes style={{ marginRight: 8, width: 16 }} /> Finalizar
              processo
            </TextoOpcao>
          </Opcao>
          <div style={{ padding: "0 12px" }}>
            <hr />
          </div>
          <Opcao>
            <TextoOpcao onClick={onClickEditarDespachante}>
              <FaEdit style={{ marginRight: 8, width: 16 }} />
              Editar Despachante
            </TextoOpcao>
          </Opcao>
          <Opcao>
            <TextoOpcao onClick={onClickEditarAgenteFinanceiro}>
              <FaEdit style={{ marginRight: 8, width: 16 }} />
              Editar Agente Financeiro
            </TextoOpcao>
          </Opcao>
          <Opcao>
            <TextoOpcao onClick={onClickReprocessar}>
              <FaUndo style={{ marginRight: 8, width: 16 }} /> Reprocessar
            </TextoOpcao>
          </Opcao>
        </div>
      )}
    </Container>
  );
}
