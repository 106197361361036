import { PrimaryButton } from "components/Button/style";
import styled from "styled-components";

export const Header = styled.h2`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
`;

export const TextoHeader = styled.span`
  color: ${({ theme }) => theme.colors.neutral.neutral90};
  font-weight: bold;
  font-size: 1.2rem;
  padding-top: 6px;
`;

export const Container = styled.div`
  margin-top: 2.5rem;
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`;

interface TagProps {
  tipo: 1 | 2 | 3;
}

const tagStyleStrategy = {
  1: { background: "#F5F5F5", color: "#7F7F7F" },
  2: { background: "#E9E9FE", color: "#2529F4" },
  3: { background: "#FFE4E4", color: "#EE5353" },
};

export const Tag = styled.div<TagProps>`
  background-color: ${({ tipo }) => tagStyleStrategy[tipo].background};
  border: 1px solid ${({ tipo }) => tagStyleStrategy[tipo].color};
  border-radius: 50px;
  color: ${({ tipo }) => tagStyleStrategy[tipo].color};
  padding: 5px 8px;
  text-align: center;
  width: 120px;
`;

export const Button = styled(PrimaryButton)`
  width: 100px;
  padding: 0.5rem 1rem;
  font-size: 12px !important;
`;

export const DivAcoes = styled.div`
  position: absolute;
  right: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`;
